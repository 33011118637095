import React, { useCallback, useEffect, useState } from 'react';
import { CardContainer, ColumnContainer } from '../global/containers';
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from '../global/customTable';
import VehicleTripModal from '../modals/vehicleTripModal';
import { getElectricVehiclesTrips } from '../../services/electricVehicle';
import { formatDate } from '../../util/util';
import { useParams } from 'react-router-dom';

export default function RecentVehicleTripsCard() {
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [vehicleTripsList, setVehicleTripsList] = useState([]);
	const [selectedTrip, setSelectedTrip] = useState({});
	const params = useParams();

	const vehicleTripsColumns = [
		{
			field: 'tripDate',
			headerName: 'Trip Date',
			width: 100,
		},
		{
			field: 'start_time',
			headerName: 'Start',
			minWidth: 100,
		},
		{
			field: 'end_time',
			headerName: 'End',
			minWidth: 100,
		},
		{
			field: 'duration',
			headerName: 'Duration',
			minWidth: 200,
			flex: 1,
		},
		{
			field: 'average_speed',
			headerName: 'Average Speed',
			minWidth: 100,
			flex: 1,
			valueFormatter: (value) => `${value} Kms/h`,
		},
		{
			field: 'distance',
			headerName: 'Distance',
			minWidth: 100,
			flex: 1,
			valueFormatter: (value) => `${value} Kms`,
		},
		{
			field: 'energyConsumed',
			headerName: 'Energy Consumed',
			minWidth: 100,
			flex: 1,
			valueFormatter: (value) => `${value} %`,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					width="100px"
					menuOptions={[
						{
							label: 'View Trip',
							onClickHandler: () => {
								setModalOpen(true);
								setSelectedTrip(params.row);
							},
						},
					]}
				/>
			),
		},
	];

	const fetchVehicleTrips = useCallback(
		async function () {
			setIsLoading(true);
			getElectricVehiclesTrips(params.id)
				.then((res) => {
					const trips = res.map((trip) => {
						return {
							...trip,
							id: trip.trip_id,
							tripDate: formatDate(trip.trip_date),
							duration: trip.total_time,
							distance: trip.distance,
							energyConsumed: trip.battery_percentage_used,
						};
					});
					setVehicleTripsList(trips);
				})
				.catch((err) => {
					setVehicleTripsList([]);
				})
				.finally(() => setIsLoading(false));
		},
		[params.id]
	);

	const toggleModal = useCallback(
		async function () {
			setModalOpen(!modalOpen);
		},
		[modalOpen]
	);

	useEffect(() => {
		fetchVehicleTrips();
	}, [fetchVehicleTrips]);

	return (
		<CardContainer
			title="Recent Electric Vehicle Trips"
			width="100%"
			height="100%"
			childrenContainerWidth="97%"
			extraSX={{ overflow: 'Visible' }}
		>
			<ColumnContainer
				width={'100%'}
				height="100%"
				extraSX={{ margin: 0, padding: 0 }}
			>
				<CustomDataGrid
					rows={vehicleTripsList}
					columns={vehicleTripsColumns}
					height="65vh"
					width="100%"
					isEmptyMessage="There are no trips completed on this electric vehicle."
					isLoading={isLoading}
				/>
			</ColumnContainer>
			<VehicleTripModal
				toggleModal={toggleModal}
				modalOpen={modalOpen}
				tripdata={selectedTrip}
			/>
		</CardContainer>
	);
}
