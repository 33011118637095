import React, { useEffect, useState } from 'react'
import { ColumnContainer, ModalContainer, RowContainer } from '../global/containers'
import InfoDetails from '../global/infoText'
import { formatDate } from '../../util/util'
import { getTripLocationNames } from '../../services/electricVehicle'
import Map from '../global/map'

export default function VehicleTripModal({ modalOpen , toggleModal, tripdata}) {
  const [startLocationName, setStartLocationName] = useState('Unknown');
  const [endLocationName, setEndLocationName] = useState('Unknown');

  useEffect(() => {
    if(modalOpen) {
      const data = {
        end_location: tripdata?.end_location,
        start_location: tripdata?.start_location,
      }
      getTripLocationNames(data)
        .then((res) => {
          setStartLocationName(res?.start_location_name)
          setEndLocationName(res?.end_location_name)
        })
    }
  }, [modalOpen, tripdata?.end_location, tripdata?.start_location])

  return (
    <ModalContainer
      title='Electric Vehicle Trip'
      modalOpen={modalOpen}
      height="75%"
      width='75%' 
      type = "in-actionable"
    handleCloseModal={toggleModal}
    handleCancelClick={toggleModal}
    handleActionClick={toggleModal}
    >
      <RowContainer
        justifyContent='space-between'
        padding='0px'
        marginBottom='0px'
        extraSX={{

        }}
      >
        <ColumnContainer padding='0px' width='48%' alignItems='left'>
          <InfoDetails label={'Trip Date'} value={formatDate(tripdata?.trip_date)} />
          <InfoDetails label={'Start Location'} value={startLocationName} />
          <InfoDetails label={'Start Charge Level'} value={`${tripdata?.start_battery ?? "-"} %`} />
          <InfoDetails label={'Trip Duration'} value={tripdata?.total_time} />
          <InfoDetails label={'Total Energy Consumed'} value={`${tripdata?.battery_percentage_used ?? "-"} %`} />
        </ColumnContainer>
        <ColumnContainer padding='0px' width='48%' alignItems='left'>
          <InfoDetails label={'Distance Covered'} value={`${tripdata?.distance ?? "-"} KMs`}  />
          <InfoDetails label={'End Location'} value={endLocationName}  />
          <InfoDetails label={'End Charge Level'} value={`${tripdata?.end_battery ?? "-"} %`}  />
          <InfoDetails label={'Battery Efficiency'} value={tripdata?.battery_efficiency}  />
          <InfoDetails label={'Trip Energy Bill'} value={tripdata?.trip_cost}  />
        </ColumnContainer>
      </RowContainer>
      <Map 
        pathFollowed= {tripdata?.path_followed }
      />
    </ModalContainer>
  )
}
