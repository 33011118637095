import { useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_MESSAGE } from '../../state/slices/messages';
import enzi_theme from '../../theme/theme';
import { ColumnContainer, RowContainer } from '../global/containers';
import MessageAlert from '../global/messageAlert';

const UnprotectedLayout = ({ children }) => {
	// const isMobile = useMediaQuery('(min-width:1200px)');

	const [showMessage, setShowMessage] = useState(false);
	const appMessage = useSelector((state) => state.message);
	const dispatch = useDispatch();

	const handleCloseMessage = useCallback(() => {
		setShowMessage(false);
		dispatch(CLEAR_MESSAGE());
	}, [dispatch]);

	useEffect(() => {
		if (appMessage.message) {
			setShowMessage(true);
		}
	}, [appMessage]);
	return (
		<RowContainer
			width="100vw"
			height={{ xs: 'auto', lg: '100vh' }}
			extraSX={{
				margin: 0,
				overflowX: 'auto',
				backgroundColor: enzi_theme.palette.common.white,
				padding: 0,
				position: 'relative',
			}}
			responsive={false}
		>
            <MessageAlert
				showMessage={showMessage}
				appMessage={appMessage}
				handleCloseMessage={handleCloseMessage}
			/>
            <ColumnContainer
				width="100%"
				height="100vh"
				justifyContent="flex-start"
				alignItems="center"
				marginBottom="0px"
				padding="0px 20px"
				extraSX={{
					margin: 0,
					backgroundColor: enzi_theme.palette.common.white,
				}}
			>
				{children}
			</ColumnContainer>
        </RowContainer>
	);
};

export default UnprotectedLayout;
