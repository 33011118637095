import React, { useState } from "react";
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from "../global/containers";
import CustomDatePicker from "./customDatePicker";
import dayjs from "dayjs";

function DateRangeReportModal({
	title = "Download Report",
	modalOpen,
	actionButtonText = "Download",
	handleCloseModal,
	handleCancelClick,
	handleActionClick,
	handleStartDateChange,
	handleEndDateChange,
	startDate,
	endDate,
	startDateLabel = "Start Date",
	endDateLabel = "End Date",
	minDate,
	maxDate = dayjs().endOf("day"),
	additionalContent,
	isDateRequired = true,
	isActionDisabled = false,
}) {
	const [error, setError] = useState("");

	const handleActionClickWithValidation = () => {
		if (isDateRequired && (!startDate || !endDate)) {
			setError("Please select both start and end dates.");
		} else if (
			startDate &&
			endDate &&
			dayjs(startDate).isAfter(dayjs(endDate))
		) {
			setError("Start date cannot be greater than end date.");
		} else {
			setError("");
			handleActionClick();
		}
	};

	return (
		<ModalContainer
			title={title}
			modalOpen={modalOpen}
			height="auto"
			actionButtonText={actionButtonText}
			handleCloseModal={handleCloseModal}
			handleCancelClick={handleCancelClick || handleCloseModal}
			handleActionClick={handleActionClickWithValidation}
			actionButtonDisabled={
				isActionDisabled ||
				(isDateRequired && (!startDate || !endDate)) ||
				(startDate &&
					endDate &&
					dayjs(startDate).isAfter(dayjs(endDate)))
			}
		>
			<RowContainer
				justifyContent="space-between"
				padding="0px"
				marginBottom="0px"
				alignItems="start"
				width="100%"
				extraSX={{ flexWrap: { xs: "wrap", lg: "nowrap" } }}
			>
				<ColumnContainer extraSX={{ width: { xs: "100%", lg: "50%" } }}>
					<CustomDatePicker
						label={`${startDateLabel}${
							isDateRequired ? "" : " (Optional)"
						}`}
						handleChange={handleStartDateChange}
						value={startDate}
						minDate={minDate}
						maxDate={maxDate}
					/>
				</ColumnContainer>
				<ColumnContainer extraSX={{ width: { xs: "100%", lg: "50%" } }}>
					<CustomDatePicker
						label={`${endDateLabel}${
							isDateRequired ? "" : " (Optional)"
						}`}
						handleChange={handleEndDateChange}
						value={endDate}
						minDate={minDate}
						maxDate={maxDate}
					/>
				</ColumnContainer>
			</RowContainer>
			{error && (
				<RowContainer
					justifyContent="center"
					padding="16px 0 0 0"
					marginBottom="0px"
					alignItems="center"
					width="100%"
				>
					<span style={{ color: "red" }}>{error}</span>
				</RowContainer>
			)}
			{additionalContent && (
				<RowContainer
					justifyContent="space-between"
					padding="16px 0 0 0"
					marginBottom="0px"
					alignItems="start"
					width="100%"
				>
					{additionalContent}
				</RowContainer>
			)}
		</ModalContainer>
	);
}

export default DateRangeReportModal;
