import React, { useCallback, useEffect, useState } from 'react';
import { ColumnContainer } from '../components/global/containers';
import ChargeStationStatistics from '../components/cards/chargeStationStatistics';
import TopBar from '../components/global/topBar';
import ChargeListCard from '../components/cards/chargeListCard';
import { chargingStationMetrics } from '../services/metricsService';
import { handleErrorResponse } from '../util/util';

export default function ChargeStationListPage() {
	const [loading, setLoading] = useState(true);
	const [statistics, setStatistics] = useState({});
	const [chargeStationList, setChargeStationList] = useState([]);
		const [timeRange, setTimeRange] = useState('day');

	const fetchChargeStationList = useCallback(async function (range) {
			setLoading(true);
			try {
				const res = await chargingStationMetrics(range);
				setStatistics(res);
				if (res && Array.isArray(res.stations)) {
					const formattedData = res.stations.map((station) => ({
						id: station.id,
						chargeStationName: station.name,
						dailySwapAverage: Number(
							(
								(station.g3_swaps + station.g5_swaps) /
								(range === 'week' ? 7 : range === 'day' ? 1 : 30)
							).toFixed(2)
						),
						monthsRevenue: parseFloat(station.revenue),
						battery_dispatched: station.battery_inventory_count,
						capacity: station.capacity,
						utilization: station.utilization_rate,
						powerConsumed: station.power_consumed,
						batteries_dispatched: station.batteries_dispatched,
						...station,
					}));
					setChargeStationList(formattedData);
				} else {
					setChargeStationList([]);
				}
			} catch (error) {
				handleErrorResponse('Error fetching charging station metrics');
				setChargeStationList([]);
			} finally {
				setLoading(false);
			}
		}, []);
	
		useEffect(() => {
			fetchChargeStationList(timeRange);
		}, [fetchChargeStationList, timeRange]);

	return (
    <ColumnContainer>
      <TopBar />
      <ChargeStationStatistics loading={loading} statistics={statistics} />
      <ChargeListCard
        isLoading={loading}
        chargeStationList={chargeStationList}
        showSearch={true}
				setTimeRange={setTimeRange}
				timeRange={timeRange}
				fetchChargeStationList={fetchChargeStationList}
      />
    </ColumnContainer>
  );
}
