import React from "react";
import { ColumnContainer, RowContainer } from "../global/containers";
import enzi_theme from "../../theme/theme";
import { Box } from "@mui/material";
import SidebarItem from "../sidebarItem";

const sidebarLogo = require("../../assets/Enzi-Logo.png");

export default function SidebarModule({width = '15%',extraSX = {}}) {
  return (
    <ColumnContainer
      alignItems="center"
      justifyContent="flex-start"
      height="100vh"
      width={width}
      padding="0px 15px"
      extraSX={{
        backgroundColor: enzi_theme.palette.background.paper,
        margin: 0,
        ...extraSX
      }}
    >
      <RowContainer
        width="100%"
        height="15%"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          src={sidebarLogo}
          alt="sidebar-logo"
          sx={{
            width: "150px",
            height: "auto",

            objectFit: "contain",
            margin: "10px",
          }}
        />
      </RowContainer>
      <ColumnContainer
        alignItems="center"
        justifyContent="center"
        // height="80%"
        width="100%"
        extraSX={{
          backgroundColor: enzi_theme.palette.background.paper,
          margin: 0,
          padding: 0,
        }}
      >
        <SidebarItem data={{ label: "Dashboard", uri: "/dashboard" }} />
        <SidebarItem data={{ label: "Assets Map", uri: "/assets" }} />
        <SidebarItem data={{ label: "Battery Assets", uri: "/batteries" }} />
        <SidebarItem data={{ label: "Tracker Assets", uri: "/trackers" }} />
        <SidebarItem data={{ label: "Vehicle Assets", uri: "/electric-vehicles" }} />
        <SidebarItem data={{ label: "Manage Riders", uri: "/riders" }} />
        <SidebarItem data={{ label: "Swap Stations", uri: "/swap-station" }} />
        <SidebarItem
          data={{ label: "Charge Stations", uri: "/charge-station" }}
        />
        <SidebarItem data={{ label: "Staff Management", uri: "/staff_management" }} />
      </ColumnContainer>
      {/* <SidebarLogoutItem /> */}
    </ColumnContainer>
  );
}
