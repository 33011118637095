import React, { useRef, useState } from "react";
import { ModalContainer } from "../global/containers";
import { Box, useMediaQuery } from "@mui/material";
import { issueErrorMessage } from "../../util/util";

export default function AddNewStationModal({
	modalOpen = false,
	toggleModal,
	FormComponent,
	addStationService,
	title,
	actionButtonText,
	onSuccess,
}) {
	const handleSubmitRef = useRef();
	const [isLoading, setIsLoading] = useState(false);
	const isMobile = useMediaQuery("(max-width:600px)");

	const handleSubmit = () => {
		const stationData = handleSubmitRef.current.getData();
		if (stationData) {
			onSubmit(stationData);
		} else {
			issueErrorMessage("Please fill in all required fields correctly.");
		}
	};

	const onSubmit = (data) => {
		setIsLoading(true);
		addStationService(data)
			.then((res) => {
				onSuccess(res);
			})
			.catch((err) => {
				console.error("Error adding station:", err);
				issueErrorMessage(
					"An error occurred while adding the station. Please try again."
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<ModalContainer
			title={title}
			modalOpen={modalOpen}
			height={isMobile ? "80vh" : "80vh"}
			width={isMobile ? "80vw" : "49vw"}
			actionButtonText={actionButtonText}
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={handleSubmit}
			isLoading={isLoading}
		>
			<Box
				sx={{
					height: "calc(100% - 64px)",
					overflowY: "auto",
					padding: "16px",
				}}
			>
				<FormComponent ref={handleSubmitRef} />
			</Box>
		</ModalContainer>
	);
}
