import { configureStore } from '@reduxjs/toolkit';
import { messageSlice } from './state/slices/messages';
import { combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist';
import { authSlice } from './state/slices/auth';

const rootReducer = combineReducers({
	message: messageSlice.reducer,
	auth: authSlice.reducer,
});

const rootPersist = {
	key: 'root',
	storage: storage,
};
const persistedRootReducer = persistReducer(rootPersist, rootReducer);

export const store = configureStore({
	reducer: persistedRootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(thunk),
});

export const persistor = persistStore(store);

export const appState = store.getState();
export const appDispatch = store.dispatch;
