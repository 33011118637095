import React, { useCallback, useEffect, useState } from "react";
import { ColumnContainer, RowContainer } from "../global/containers";
import SidebarModule from "../modules/sidebar";
import enzi_theme from "../../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FloatingActionButton } from "../global/button";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useMediaQuery } from "@mui/material";
import { CLEAR_MESSAGE } from "../../state/slices/messages";
import MessageAlert from "../global/messageAlert";


export default function ProtectedLayout({ children }) {

  const authState = useSelector((state) => state.auth);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const isMobile = useMediaQuery('(min-width:1200px)');
  const navigation = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
	const appMessage = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const handleCloseMessage = useCallback(() => {
    setShowMessage(false);
    dispatch(CLEAR_MESSAGE());
  }, [dispatch]);

	useEffect(() => {
		if (appMessage.message) {
			setShowMessage(true);
		}
	}, [appMessage]);

  const toggleSidebar = useCallback(
    async function () {
      console.log(sidebarOpen);
      setSidebarOpen(!sidebarOpen);
    },
    [sidebarOpen]
  );


  useEffect(() => {
    if (authState !== undefined && authState.isAuthenticated === false) {
      navigation("/");
    } else if (authState === undefined) {
      navigation("/");
    }
  }, [authState, navigation]);

  return (
    <RowContainer
      width="100vw"
      height={{ xs: "auto", lg: "100vh" }}
      extraSX={{
        margin: 0,
        overflowX: "auto",
        backgroundColor: enzi_theme.palette.common.white,
        padding: 0,
        position: 'relative'
      }}
      responsive={false}
    >
      <MessageAlert
        showMessage={showMessage}
        appMessage={appMessage}
        handleCloseMessage={
          handleCloseMessage
        }
      />
      {isMobile && <FloatingActionButton padding="5px" style={{
        position: 'absolute',
        zIndex: '9000',
        left: sidebarOpen ? '12.5%' : '1%',
        top: '1%',
      }}
        icon={sidebarOpen ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
        onclick={toggleSidebar}
      />}
      {sidebarOpen && <SidebarModule extraSX={{ display: { lg: 'block', xs: 'none' } }} />}
      <ColumnContainer
        width="100%"
        height="100vh"
        justifyContent="flex-start"
        alignItems="center"
        marginBottom="0px"
        padding="0px 20px"
        extraSX={{
          margin: 0,
          backgroundColor: enzi_theme.palette.common.white
        }}
      >
        {children}
      </ColumnContainer>
    </RowContainer>
  );
}
