import { handleErrorResponse, issueSuccessMessage } from '../util/util';
import {
	ADD_ELECTRIC_VEHICLE,
	AVAILABLE_ELECTRIC_VEHICLE_LIST,
	DEL_ELECTRIC_VEHICLE,
	EDIT_ELECTRIC_VEHICLE,
	ELECTRIC_VEHICLE,
	ELECTRIC_VEHICLE_INFO,
	ELECTRIC_VEHICLE_LIST,
	ELECTRIC_VEHICLE_OVERVIEW,
	ELECTRIC_VEHICLE_STATS,
	ELECTRIC_VEHICLE_TRIPS,
	TRIP_LOCATIONS,
} from '../constants/url.constants';
import { serviceAPI } from './service';

export const getElectricVehicles = async (id) => {
	try {
		const response = await serviceAPI.get(ELECTRIC_VEHICLE + `${id}`);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const listElectricVehicles = async () => {
	try {
		const response = await serviceAPI.get(ELECTRIC_VEHICLE_LIST);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const listAvailableElectricVehicles = async () => {
	try {
		const response = await serviceAPI.get(AVAILABLE_ELECTRIC_VEHICLE_LIST);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const deleteElectricVehicles = async (id) => {
	try {
		const response = await serviceAPI.delete(DEL_ELECTRIC_VEHICLE + `${id}`);
		issueSuccessMessage('Electric Vehicle Deleted Successfully');
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const addElectricVehicles = async (data) => {
	try {
		return await serviceAPI.post(ADD_ELECTRIC_VEHICLE, data);
	} catch (error) {
		handleErrorResponse(error);
		throw error;
	}
};

export const editElectricVehicles = async (data, id) => {
	try {
		const response = await serviceAPI.put(
			EDIT_ELECTRIC_VEHICLE + `${id}`,
			data
		);
		issueSuccessMessage('Electric Vehicle Updated Successfully');
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
		throw error;
	}
};

export const getElectricVehiclesStats = async (data) => {
	try {
		const response = await serviceAPI.get(ELECTRIC_VEHICLE_STATS, {
			params: data,
		});
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const getElectricVehiclesInfo = async (id) => {
	try {
		const response = await serviceAPI.get(ELECTRIC_VEHICLE_INFO + `${id}`);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const getElectricVehiclesTrips = async (id) => {
	try {
		const response = await serviceAPI.get(ELECTRIC_VEHICLE_TRIPS + `${id}`);
		return response.data.trips;
	} catch (error) {
		if (!error.response.data.error.includes('vehicle')) {
			handleErrorResponse(error);
			throw error;
		}
	}
};

export const getElectricVehicleOverview = async (id) => {
	try {
		const response = await serviceAPI.get(ELECTRIC_VEHICLE_OVERVIEW);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const getTripLocationNames = async (data) => {
	try {
		const response = await serviceAPI.post(TRIP_LOCATIONS, data);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};
