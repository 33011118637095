import { serviceAPI, serviceAPIDownloadFile } from "../services/service";
import {
	DEL_SWAP_STATION,
	EDT_SWAP_STATION,
	LIST_SWAP_STATION,
	SAVE_SWAP_STATION,
	SWAP_STATION_DETAILS,
	SWAP_STATION_ADD_USER,
	SWAP_STATION_DEL_USER,
	SWAP_STATION_EDT_USER,
	SWAP_STATION_OPERATIONS,
	SWAPPING_STATION_METRICS,
} from "../constants/url.constants";
import { handleErrorResponse, issueSuccessMessage } from "../util/util";
import dayjs from "dayjs";

// export const getSwapStation = async (id) => {
// 	try {
// 		const response = await serviceAPI.get(`${SWAP_STATION}${id}`);
// 		return response.data;
// 	} catch (error) {
// 		handleErrorResponse(error);
// 	}
// };

export const listSwapStations = async () => {
	try {
		const response = await serviceAPI.get(LIST_SWAP_STATION);

		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const deleteSwapStation = async (id) => {
	try {
		const response = await serviceAPI.delete(`${DEL_SWAP_STATION}${id}`);
		issueSuccessMessage("Swap Station Deleted Successfully");
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const addSwapStation = async (data) => {
	try {
		const response = await serviceAPI.post(SAVE_SWAP_STATION, data);
		issueSuccessMessage("Swap Station Added Successfully");
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const editSwapStation = async (data, id) => {
	try {
		const response = await serviceAPI.put(`${EDT_SWAP_STATION}${id}`, data);
		issueSuccessMessage("Swap Station Updated Successfully");
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
	}
};

export const getSwapStationDetails = async (id) => {
	return serviceAPI
		.get(SWAP_STATION_DETAILS, { params: { station_id: id } })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const addSwapStationUser = async (data) => {
	return serviceAPI
		.post(SWAP_STATION_ADD_USER, data)
		.then((response) => {
			issueSuccessMessage("Swap Station User Added Successfully");
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const editSwapStationUser = async (data, id) => {
	const requestData = { ...data, id };
	return serviceAPI
		.put(SWAP_STATION_EDT_USER, requestData)
		.then((response) => {
			issueSuccessMessage("Swap Station User Edited Successfully");
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const deleteSwapStationUser = async (id) => {
	return serviceAPI
		.delete(SWAP_STATION_DEL_USER, { data: { user_id: id } })
		.then((response) => {
			issueSuccessMessage("Swap Station User Deleted Successfully");
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const requestedDispatchOperations = async (id) => {
	return serviceAPI
		.post(SWAP_STATION_OPERATIONS, { station_id: id })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

export const downloadSwapStationMetrics = async (startDate, endDate) => {
	let params = {};

	if (startDate) {
		params.start_date = dayjs(startDate).format("YYYY-MM-DD");
	}

	if (endDate) {
		params.end_date = dayjs(endDate).format("YYYY-MM-DD");
	}

	return serviceAPIDownloadFile
		.get(SWAPPING_STATION_METRICS, { params })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
