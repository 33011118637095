import React, { useEffect, useRef, useState } from "react";
import {
	CardContainer,
	ColumnContainer,
	RowContainer,
} from "../global/containers";
import { BarChartItem } from "../global/charts";
import enzi_theme from "../../theme/theme";
import { requestedDispatchOperations } from "../../services/swapStation";
import LoadingOverlay from "../overlays/loading";
import { EmptyOverlays } from "../global/overlays";

export default function SessionSwapsCard({ station }) {
	const cardContainerRef = useRef();
	const [cardContainerWidth, setCardContainerWidth] = useState(undefined);
	const [swapOperations, setSwapOperations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (cardContainerRef !== null && cardContainerRef.current !== null) {
			const containerWidth = cardContainerRef.current.getContainerWidth();
			if (!isNaN(containerWidth)) {
				setCardContainerWidth(containerWidth * 2);
			}
		}
	}, [cardContainerRef]);

	useEffect(() => {
		if (station && station.id) {
			setIsLoading(true);
			requestedDispatchOperations(station.id)
				.then((data) => {
					if (data && data.battery_swaps) {
						setSwapOperations(data.battery_swaps);
					} else {
						console.error("Unexpected data structure:", data);
						setSwapOperations([]);
					}
					setIsLoading(false);
				})
				.catch((error) => {
					console.error("Error fetching swap operations:", error);
					setIsLoading(false);
				});
		}
	}, [station]);

	// Get unique years from the swap operations
	const getUniqueYears = () => {
		return [...new Set(swapOperations.map(swap => {
			const [month, day, year] = swap.operation_date.split('/');
			return year;
		}))].sort();
	};

	// Generate months list for each year up to current month
	const generateMonthsList = () => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
					   "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		
		// Find latest date in swap operations
		const latestDate = swapOperations.reduce((maxDate, swap) => {
			const [month, day, year] = swap.operation_date.split('/');
			const date = new Date(`${year}-${month}-${day}`);
			return date > maxDate ? date : maxDate;
		}, new Date(0));

		// Calculate start date (11 months back from latest date)
		const startDate = new Date(latestDate);
		startDate.setMonth(latestDate.getMonth() - 12);
		startDate.setDate(1); // Ensure we start at beginning of month
		
		const monthList = [];
		let currentDate = new Date(startDate);
		
		// Add months until we pass the latest date
		while (currentDate <= latestDate) {
			const monthName = months[currentDate.getMonth()];
			const year = currentDate.getFullYear().toString().slice(-2);
			monthList.push(`${monthName} ${year}`);
			
			// Move to next month
			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		return monthList;
	};

	// Process the swaps data
	const processedData = swapOperations.reduce((acc, swap) => {
		const [month, day, year] = swap.operation_date.split("/");
		const date = new Date(`${year}-${month}-${day}`);
		if (isNaN(date.getTime())) {
			console.error("Invalid date:", swap.operation_date);
			return acc;
		}
		const key = date.toLocaleString("default", {
			month: "short",
			year: "2-digit"
		});

		if (!acc[key]) {
			acc[key] = { issuedSwaps: 0, returnedSwaps: 0 };
		}

		if (swap.operation_type === "Issued") {
			acc[key].issuedSwaps += 1;
		} else if (swap.operation_type === "Received") {
			acc[key].returnedSwaps += 1;
		}

		return acc;
	}, {});

	// Get the dynamic list of months
	const uniqueYears = getUniqueYears();
	const allMonths = generateMonthsList();

	// Ensure all months are included in processedData
	allMonths.forEach((month) => {
		if (!processedData[month]) {
			processedData[month] = { issuedSwaps: 0, returnedSwaps: 0 };
		}
	});

	const dataLabels = allMonths;
	const issuedSwaps = dataLabels.map((key) => processedData[key].issuedSwaps);
	// const returnedSwaps = dataLabels.map(
	// 	(key) => processedData[key].returnedSwaps
	// );

	const maxValue = Math.max(...issuedSwaps);
	const roundedMax = Math.ceil(maxValue / 5) * 5;  // Round up to nearest 5

	return (
		<CardContainer
			ref={cardContainerRef}
			title="Station Swaps"
			width="100%"
			height="auto"
			extraSX={{
				overflow: "visible",
				marginTop: "10px",
				alignItems: "flex-start",
			}}
			childrenContainerWidth="98%"
		>
			{isLoading ? (
				<LoadingOverlay />
			) : swapOperations.length === 0 ? (
				<EmptyOverlays />
			) : (
				<ColumnContainer
					width="100%"
					height="auto"
					padding="5px 10px"
					marginBottom="5px"
					extraSX={{
						gap: "10px",
						overflow: "visible",
					}}
				>
					<RowContainer
						justifyContent="space-between"
						alignItems="start"
						padding="0px"
						marginBottom="0px"
						responsive={false}
						extraSX={{
							gap: "10px",
							overflow: "visible",
						}}
					>
						<BarChartItem
							height={
								cardContainerWidth !== undefined
									? Math.min(400, cardContainerWidth * (15 / 50))  // Cap at 400px
									: 400
							}
							width={cardContainerWidth * 0.48}
							dataLabels={dataLabels}
							dataSeries={[
								{
									data: issuedSwaps,
									color: enzi_theme.palette.secondary.main,
									valueFormatter: (value) => `${value}`,
									label: "Swaps",
								},
							]}
							chartLabel="Station Swaps"
							minBarWidth={20}
							maxBarWidth={40}
							yAxisConfig={{
								min: 0,
								max: roundedMax,
							}}
						/>
					</RowContainer>
				</ColumnContainer>
			)}
		</CardContainer>
	);
}
