import React, { useEffect, useState } from 'react';
import { RowContainer } from '../global/containers';
import StatItem from '../global/statItem';
import { batteryAssetsMetrics } from '../../services/metricsService';
import { formatCurrency } from '../../util/util';

export default function BatteryStaticsCard() {
	const [loading, setIsLoading] = useState(false);
	const [activeBatteries, setActiveBatteries] = useState('_');
	const [totalBatteries, setTotalBatteries] = useState('_');
	const [chargeStations, setChargeStations] = useState('_');
	const [swapStations, setSwapStations] = useState('_');
	const [revenue, setRevenue] = useState('_');
	const cards = [
		{
			title: 'Total Batteries',
			value: totalBatteries,
		},
		{
			title: 'Active Batteries',
			value: activeBatteries,
		},
		{
			title: 'Batteries in Charge Stations',
			value: chargeStations,
		},
		{
			title: 'Batteries in Swap Stations',
			value: swapStations,
		},
		{
			title: 'Total Month’s Battery Revenue',
			value: formatCurrency(revenue),
		},
	];

	useEffect(() => {
		setIsLoading(true);
		batteryAssetsMetrics().then((response) => {
			setActiveBatteries(response.activeBatteries);
			setTotalBatteries(response.totalBatteries);
			setChargeStations(response.chargeStationBatteries);
			setSwapStations(response.swapStationBatteries);
			setRevenue(response.monthRevenue);
		});
		setIsLoading(false);
	}, []);
	return (
		<RowContainer
			justifyContent="flex-start"
			width="100%"
			responsive={false}
			extraSX={{ gap: '10px', flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
		>
			{cards.map((card, index) => (
				<StatItem
					key={index}
					width="25%"
					loading={loading}
					title={card.title}
					value={card.value}
				/>
			))}
		</RowContainer>
	);
}
