import React, { useCallback, useEffect, useState } from 'react';
import { CardContainer, ColumnContainer } from '../global/containers';
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from '../global/customTable';
import { useNavigate } from 'react-router-dom';
import {
	deleteElectricVehicles,
	listElectricVehicles,
} from '../../services/electricVehicle';
import ConfirmationModal from '../modals/confirmationModal';
import ActionableComponent from '../global/actionableComponent';
import AddEditVehicleModal from '../modals/addEditVehicleModal';

const ECI_STATES = {
	mv: 'moving',
	idd: 'idling',
	off: 'off',
};

export default function VehicleListCard() {
	const [filteredData, setFilteredData] = useState();

	const navigator = useNavigate();
	const [addEditModal, setAddEditModal] = useState(false);
	const [vehicleToEdit, setVehicleToEdit] = useState(null);
	const [vehicleList, setVehicleList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [vehicleToDelete, setVehicleToDelete] = useState(null);

	const vehicleColumns = [
		{
			field: 'vehicleSerial',
			headerName: 'Vehicle Serial No.',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'currentMilage',
			headerName: 'Current Mileage (km)',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'riderName',
			headerName: 'Rider Name',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'vehicleRegistration',
			headerName: 'Registration No.',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'pairedBatterySn',
			headerName: 'Paired Battery S/N',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'eciState',
			headerName: 'ECI State',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			flex: 1,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					menuOptions={[
						{
							label: 'View',
							onClickHandler: () =>
								navigator(`/electric-vehicles/${params.row.id}`),
						},
						{
							label: 'Edit',
							onClickHandler: () => editElectricVehicle(params.row),
						},
						{
							label: 'Delete',
							onClickHandler: () => handleDelete(params.row),
						},
					]}
				/>
			),
		},
	];

	const fetchVehicleListData = useCallback(async function () {
		setIsLoading(true);
		listElectricVehicles()
			.then((electricVehicles) => {
				const formatedData = electricVehicles.map((vehicle) => {
					const riderName = `${vehicle?.rider?.first_name} ${vehicle?.rider?.last_name} `;

					return {
						id: vehicle.id,
						vehicleSerial: vehicle.serial_number,
						currentMilage: vehicle.mileage ?? 0,
						riderName: riderName,
						vehicleRegistration: vehicle?.registration_number,
						pairedBatterySn: vehicle.battery?.bat_sn ?? '-',
						eciState: ECI_STATES[vehicle?.eci?.state],
						...vehicle,
					};
				});
				setVehicleList(formatedData);
				setFilteredData(formatedData);
			})
			.catch(() => {
				setVehicleList([]);
			})
			.finally(() => setIsLoading(false));
	}, []);

	const filter = (ev) => {
		const param = ev.target.value.toLocaleLowerCase().trim();
		if (param === '') {
			return setFilteredData(vehicleList);
		}
		const filters = (vehicle) =>
			vehicle?.registration_number.toLocaleLowerCase().includes(param) ||
			vehicle?.rider?.first_name.toLocaleLowerCase().includes(param) ||
			vehicle?.rider?.last_name.toLocaleLowerCase().includes(param) ||
			vehicle?.pairedBatterySn.toLocaleLowerCase().includes(param);
		const formatedData = vehicleList.filter(filters);
		setFilteredData(formatedData);
	};

	const toggleDeleteModal = useCallback(
		async function () {
			setDeleteModalOpen(!deleteModalOpen);
		},
		[deleteModalOpen]
	);

	useEffect(() => {
		fetchVehicleListData();
	}, [fetchVehicleListData, addEditModal]);

	const editElectricVehicle = (data) => {
		setVehicleToEdit(data);
		setAddEditModal(true);
	};

	const handleDelete = (vehicle) => {
		setVehicleToDelete(vehicle);
		toggleDeleteModal();
	};

	const deleteEv = () => {
		deleteElectricVehicles(vehicleToDelete.id)
			.then((res) => {
				setVehicleList(
					vehicleList.filter((value) => value.id !== vehicleToDelete.id)
				);
				setFilteredData(
					filteredData.filter((value) => value.id !== vehicleToDelete.id)
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => toggleDeleteModal());
	};

	return (
		<CardContainer
			title="Electric Vehicle Assets"
			height="auto"
			width="100%"
			childrenContainerWidth="97%"
			hasActionableComponent={true}
			actionableComponent={
				<ActionableComponent
					showAddButton={true}
					showSearch={true}
					filter={filter}
					toggleAddModal={() => {
						setAddEditModal(true);
					}}
					searchPlaceholder="Search Electric Vehicles"
				/>
			}
		>
			<ColumnContainer
				width={'100%'}
				height="100%"
				extraSX={{ margin: 0, padding: 0 }}
			>
				<CustomDataGrid
					columns={vehicleColumns}
					rows={filteredData}
					isLoading={isLoading}
					isEmptyMessage="There are no electric vehicle assets to show"
					width="100%"
					height="65vh"
				/>
			</ColumnContainer>
			{addEditModal && (
				<AddEditVehicleModal
					closeModal={() => {
						setAddEditModal(false);
					}}
					modalOpen={addEditModal}
					vehicleAsset={vehicleToEdit}
				/>
			)}

			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={toggleDeleteModal}
				onConfirmation={deleteEv}
				itemType="Electric vehicle"
				itemName={vehicleToDelete?.registration_number}
				confirmType="delete"
			/>
		</CardContainer>
	);
}
