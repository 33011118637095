import React from "react";
import enzi_theme from "../../theme/theme";
import { CustomText } from "./text";
import { ColumnContainer } from "./containers";
import { CircularProgress, Tooltip } from "@mui/material";

export default function StatItem({
  value = '-',
  title,
  loading,
  width = "100%",
  marginBotton = "0px",
  height="auto"
}) {
  return (
    <ColumnContainer
      marginBottom={marginBotton}
      width={{ xs: "90%", lg: width }}
      height={height}
      alignItems="flex-start"
      extraSX={{
        backgroundColor: enzi_theme.palette.secondary.main,
        borderRadius: "10px",
        padding: "10px 15px",
        overflow: "hidden",
      }}
    >
      <CustomText
        variant="h4"
        text={loading ? <CircularProgress color="primary" /> : value}
        fontWeight="900"
        color={enzi_theme.palette.common.white}
        width="100%"
      />
      <Tooltip title={title}>
      <div style={{width: "100%"}}>
      <CustomText
        variant="body1"
        text={title}
        fontWeight="700"
        color="primary"
        extraSX={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: {xs: "100%", lg: "100%"},
          whiteSpace: "nowrap",
        }}
      />
      </div>
      </Tooltip>
    </ColumnContainer>
  );
}
