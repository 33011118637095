import React from 'react';
import { CardContainer, RowContainer } from '../global/containers';
import InfoDetails from '../global/infoText';
import { formatDate } from '../../util/util';
import { EmptyOverlays } from '../global/overlays';

export default function PairedBatteryInformationCard({ batteryInfo = {} }) {
	const pairedDate = batteryInfo?.station_info?.paired_date
		? formatDate(batteryInfo?.station_info?.paired_date)
		: '-';

	const isBatteryInfoAvailable = batteryInfo?.bat_sn;

	return (
		<CardContainer
			title="Paired Battery"
			width={{ xs: '100%', lg: '33%' }}
			height="100%"
			childrenContainerWidth="95%"
			extraSX={{
				borderRadius: '10px',
			}}
		>
			{!isBatteryInfoAvailable ? (
				<EmptyOverlays
					width="30%"
					message="This vehicle does not have a paired battery"
				/>
			) : (
				<>
					<RowContainer
						justifyContent="space-between"
						padding="0px"
						marginBottom="0px"
					>
						<InfoDetails
							label={'Battery VIN/ID'}
							width="max-content"
							value={batteryInfo?.bat_sn}
						/>
						<InfoDetails
							label={'Paired Date'}
							width="max-content"
							value={pairedDate}
							alignItems={{ xs: 'flex-start', lg: 'end' }}
						/>
					</RowContainer>
					<RowContainer
						justifyContent="space-between"
						padding="0px"
						marginBottom="0px"
					>
						<InfoDetails
							label={'Current Status'}
							width="max-content"
							value={batteryInfo?.state_name ?? ''}
						/>
						<InfoDetails
							label={'Latest Payment'}
							width="max-content"
							value={`${batteryInfo?.station_info?.latest_payment ?? '-'} Ksh`}
							alignItems={{ xs: 'flex-start', lg: 'end' }}
						/>
					</RowContainer>
					<RowContainer
						justifyContent="space-between"
						padding="0px"
						marginBottom="0px"
					>
						<InfoDetails
							label={'Paired Station'}
							width="max-content"
							value={batteryInfo?.station_info?.paired_station}
						/>
						<InfoDetails
							label={'Current Charge'}
							width="max-content"
							value={`${batteryInfo?.data?.cap ?? '-'} %`}
							alignItems={{ xs: 'flex-start', lg: 'flex-end' }}
						/>
					</RowContainer>
				</>
			)}
		</CardContainer>
	);
}
