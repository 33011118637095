import React, { useCallback, useState } from 'react';
import { ColumnContainer } from '../components/global/containers';
import TopBar from '../components/global/topBar';

export default function TrackerAssetPage() {
	const [modalOpen, setModalOpen] = useState(true);

	const toggleModal = useCallback(
		async function () {
			setModalOpen(!modalOpen);
		},
		[modalOpen]
	);

	return (
		<ColumnContainer
			height="100%"
			marginBottom="0px"
			extraSX={{
				overflowY: 'auto',
			}}
		>
			<TopBar />
		</ColumnContainer>
	);
}
