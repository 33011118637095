import React, { useCallback, useEffect, useState } from "react";
import { CardContainer } from "../global/containers";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import DispatchOperationsModal from "../modals/dispatchOperationsModal";
import { listDispatchOperation } from "../../services/chargeStationService";

export default function FulfilledDispatchOperationsCard({ stationId }) {
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [, setChargingStation] = useState(null);
	const [fulfilledDispatchList, setFulfilledDispatchList] = useState([]);
	const [selectedDispatch, setSelectedDispatch] = useState(null);

	const fulfilledDispatchOperationsColumns = [
		{
			field: "swap_station",
			headerName: "Swap Station",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "operation_status",
			headerName: "Operation Status",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "operation_type",
			headerName: "Operation Type",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "operation_date",
			headerName: "Operation Date",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "batteries_total",
			headerName: "Total Batteries",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "batteries_verified",
			headerName: "Batteries Verified",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "actions",
			headerName: "Actions",
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				return (
					<CustomDataGridActionsDropdownMenu
						width="100px"
						menuOptions={[
							{
								label: "View",
								onClickHandler: () => toggleModal(params.row),
							},
						]}
					/>
				);
			},
		},
	];

	const fetchFulfilledDispatchList = useCallback(
		async function () {
			setIsLoading(true);
			try {
				const response = await listDispatchOperation(stationId);
				setFulfilledDispatchList(response.dispatch_operations);
				setChargingStation(response.charging_station);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		},
		[stationId]
	);

	const toggleModal = useCallback(
		function (dispatch) {
			setSelectedDispatch(dispatch);
			setModalOpen((prev) => !prev);
			if (modalOpen) {
				setSelectedDispatch(null);
			}
		},
		[modalOpen]
	);

	useEffect(() => {
		fetchFulfilledDispatchList();
	}, [fetchFulfilledDispatchList]);

	return (
		<CardContainer
			title="Fulfilled Dispatch Operations"
			width="100%"
			height="100%"
			childrenContainerWidth="97%"
			padding="0"
		>
			<CustomDataGrid
				columns={fulfilledDispatchOperationsColumns}
				rows={fulfilledDispatchList}
				width="100%"
				height="100%"
				isEmptyMessage="There are no fulfilled dispatches to show."
				isLoading={isLoading}
			/>
			<DispatchOperationsModal
				modalOpen={modalOpen}
				toggleModal={toggleModal}
				selectedDispatch={selectedDispatch}
			/>
		</CardContainer>
	);
}
