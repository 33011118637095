import { Button, Typography } from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import { useState } from 'react';
import { RowContainer } from '../global/containers';
import enzi_theme from '../../theme/theme';
import { uploadPaymentRecords } from '../../services/accountsService';
import { handleErrorResponse, handleInfoResponse } from '../../util/util';

const PaymentsUploadForm = () => {
	const [value, setValue] = useState(null);

	const handleChange = (newValue) => {
		console.log(newValue);
		setValue(newValue);
	};

	const uploadFile = () => {
		if (value) {
			const formData = new FormData();
			formData.append('upload', value);
			uploadPaymentRecords(formData)
				.then((response) => {
					console.log(response);
					handleInfoResponse(response);
				})
				.catch((err) => {
					console.log(err);
					handleErrorResponse(err);
				});
		}
	};

	return (
		<RowContainer
			alignItems="center"
			width={{ xs: '65vw', lg: '55vw' }}
			padding="0px 0px"
			marginBottom="20px"
			marginTop="20px"
			extraSX={{
				gap: 1,
			}}
		>
			<Typography
				variant="body1"
				color="secondary"
				fontWeight={700}
				textAlign={'start'}
				width={'auto'}
			>
				Payment Upload
			</Typography>
			<MuiFileInput value={value} onChange={handleChange} />
			<Button
				onClick={uploadFile}
				sx={{
					backgroundColor: enzi_theme.palette.secondary.main,
					marginBottom: '10px',
					color: enzi_theme.palette.primary.main,
				}}
			>
				Upload File
			</Button>
		</RowContainer>
	);
};

export default PaymentsUploadForm;
