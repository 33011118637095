import React, { useCallback, useEffect, useState } from 'react'
import { ColumnContainer, RowContainer } from '../components/global/containers'
import TopBar from '../components/global/topBar'
import PaymentInfoCard from '../components/cards/paymentInfoCard'
import PastRiderTransactionsCard from '../components/cards/pastRiderTransactionsCard'
import RiderInfoCard from '../components/cards/riderInfoCard'
import { useParams } from 'react-router-dom'
import { riderDetails } from '../services/riderService'

export default function RiderInfo() {
    const params = useParams()
    const [riderInfo,setRiderInfo] = useState({})

    const fetchRiderInfo = useCallback(async function(){
        riderDetails(params.id)
            .then(res => {
                setRiderInfo(res)
            })
            .catch(err => console.log(err))
    },[])

    useEffect(()=>{
        fetchRiderInfo()
    },[fetchRiderInfo])

    return (
        <ColumnContainer
            height="100%"
            marginBottom="0px"
            extraSX={{
                overflowY: "auto",
            }}
        >
            <TopBar/>
            <RowContainer justifyContent="space-between"
                height="27.5vh"
                padding="0px"
                extraSX={{
                    gap: "10px",
                    paddingBottom: "10px",
                    flexWrap: { xs: "wrap", lg: "nowrap" },
                }}>
                <RiderInfoCard riderInfo={riderInfo}/>
                <PaymentInfoCard riderInfo={riderInfo}/>
            </RowContainer>
            <PastRiderTransactionsCard />
        </ColumnContainer>
    )
}
