import React, { useEffect, useState } from 'react';
import { RowContainer } from '../global/containers';
import StatItem from '../global/statItem';
import { dashboardMetrics } from '../../services/metricsService';
import { formatCurrency } from '../../util/util';

export default function StatisticsCard() {
	const [loading, setIsLoading] = useState(false);
	const [activeBatteries, setActiveBatteries] = useState('_');
	const [remainingPower, setRemainingPower] = useState('_');
	const [revenue, setRevenue] = useState('_');
	const [swaps, setSwaps] = useState('_');
	const [revPerBattery, setRevPerBattery] = useState('_');
	const cards = [
		{
			title: 'Total Active Batteries',
			value: activeBatteries,
		},
		{
			title: 'Avg Power In Active Batteries',
			value: remainingPower,
		},
		{
			title: 'Total Month’s Swaps',
			value: swaps,
		},
		{
			title: 'Total Month’s Revenue',
			value: revenue,
		},
		{
			title: 'Average Revenue per Battery',
			value: revPerBattery,
		},
	];

	useEffect(() => {
		setIsLoading(true);
		dashboardMetrics().then((response) => {
			setActiveBatteries(response?.activeBatteries ?? 0);
			setRemainingPower(`${response?.average_remaining_power ?? 0} Kwh`);
			setSwaps(response?.monthSwaps ?? 0);
			setRevenue(`${formatCurrency(response?.monthRevenue)} Ksh`);
			setRevPerBattery(`${formatCurrency(response?.monthRevPerBatt)} Ksh`);
		});
		setIsLoading(false);
	}, []);

	return (
		<RowContainer
			justifyContent="space-between"
			width="100%"
			responsive={false}
			extraSX={{ gap: 1 }}
		>
			{cards.map((card, index) => (
				<StatItem
					key={index}
					width="25%"
					title={card.title}
					loading={loading}
					value={card.value}
				/>
			))}
		</RowContainer>
	);
}
