import React, { useCallback, useEffect, useState } from 'react';
import { CardContainer, ColumnContainer } from '../global/containers';
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from '../global/customTable';
import { useNavigate } from 'react-router-dom';
import {
	deleteElectricVehicles,
	listElectricVehicles,
} from '../../services/electricVehicle';
import ConfirmationModal from '../modals/confirmationModal';
import ActionableComponent from '../global/actionableComponent';
import AddEditVehicleModal from '../modals/addEditVehicleModal';
import { isEmptyString } from '../../util/util';
import PairBatteryModal from '../modals/pairBatteryModal';

const ECI_STATES = {
	mv: 'moving',
	idd: 'idling',
	off: 'off',
};

export default function VehicleListCard() {
	const [filteredData, setFilteredData] = useState();

	const navigator = useNavigate();
	const [addEditModal, setAddEditModal] = useState(false);
	const [selectedVehicle, setSelectedVehicle] = useState(null);
	const [vehicleList, setVehicleList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [batteryPairModalOpen, setBatteryPairModalOpen] = useState(false);

	const vehicleColumns = [
		{
			field: 'chasis_number',
			headerName: 'Chasis Number',
			flex: 1.3,
			minWidth: 100,
		},
		{
			field: 'currentMilage',
			headerName: 'Current Mileage (km)',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'riderName',
			headerName: 'Rider Name',
			flex: 1.3,
			minWidth: 100,
		},
		{
			field: 'vehicleRegistration',
			headerName: 'Registration No.',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'pairedBatterySn',
			headerName: 'Paired Battery S/N',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'bmsSn',
			headerName: 'BMS S/N',
			flex: 1,
			minWidth: 100,
		},
		{
			field: 'eciState',
			headerName: 'ECI State',
			flex: 0.6,
			minWidth: 100,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			flex: 0.5,
			renderCell: (params) => {
				let options = [
					{
						label: 'View',
						onClickHandler: () =>
							navigator(`/electric-vehicles/${params.row.id}`),
					},
					{
						label: 'Edit',
						onClickHandler: () => editElectricVehicle(params.row),
					},
					{
						label: 'Delete',
						onClickHandler: () => handleDelete(params.row),
					},
				];
				if (isEmptyString(params.row.battery?.bms_sn)) {
					options = [
						...options,
						{
							label: 'Pair a Battery',
							onClickHandler: () => handleBatteryPair(params.row),
						},
					];
				}
				return <CustomDataGridActionsDropdownMenu menuOptions={options} />;
			},
		},
	];

	const fetchVehicleListData = useCallback(async function () {
		setIsLoading(true);
		listElectricVehicles()
			.then((electricVehicles) => {
				const formatedData = electricVehicles.map((vehicle) => {
					const riderName = `${vehicle?.rider?.first_name} ${vehicle?.rider?.last_name} `;
					return {
						id: vehicle.id,
						vehicleSerial: vehicle.serial_number,
						currentMilage: vehicle.mileage ?? 0,
						riderName: riderName,
						vehicleRegistration: vehicle?.registration_number,
						pairedBatterySn: vehicle.battery?.bat_sn ?? '-',
						bmsSn: vehicle.battery?.bms_sn ?? '-',
						eciState: ECI_STATES[vehicle?.eci?.state],
						...vehicle,
					};
				});
				setVehicleList(formatedData);
				setFilteredData(formatedData);
			})
			.catch(() => {
				setVehicleList([]);
			})
			.finally(() => setIsLoading(false));
	}, []);

	const filter = (ev) => {
		const param = ev.target.value.toLocaleLowerCase().trim();
		if (param === '') {
			return setFilteredData(vehicleList);
		}
		const filters = (vehicle) =>
			vehicle?.registration_number.toLocaleLowerCase().includes(param) ||
			vehicle?.rider?.first_name.toLocaleLowerCase().includes(param) ||
			vehicle?.rider?.last_name.toLocaleLowerCase().includes(param) ||
			vehicle?.pairedBatterySn.toLocaleLowerCase().includes(param);
		const formatedData = vehicleList.filter(filters);
		setFilteredData(formatedData);
	};

	useEffect(() => {
		if (!addEditModal && !batteryPairModalOpen && !deleteModalOpen) {
			fetchVehicleListData();
		}
	}, [
		fetchVehicleListData,
		addEditModal,
		batteryPairModalOpen,
		deleteModalOpen,
	]);

	const editElectricVehicle = (data) => {
		setSelectedVehicle(data);
		setAddEditModal(true);
	};

	const handleDelete = (data) => {
		setSelectedVehicle(data);
		setDeleteModalOpen(true);
	};

	const handleBatteryPair = (data) => {
		setSelectedVehicle(data);
		setBatteryPairModalOpen(true);
	};

	const deleteEv = () => {
		deleteElectricVehicles(selectedVehicle.id)
			.then((res) => {
				setVehicleList(
					vehicleList.filter((value) => value.id !== selectedVehicle.id)
				);
				setFilteredData(
					filteredData.filter((value) => value.id !== selectedVehicle.id)
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setDeleteModalOpen(false);
				setSelectedVehicle(null);
			});
	};

	return (
		<CardContainer
			title="Electric Vehicle Assets"
			height="auto"
			width="100%"
			childrenContainerWidth="97%"
			hasActionableComponent={true}
			actionableComponent={
				<ActionableComponent
					showAddButton={true}
					showSearch={true}
					filter={filter}
					toggleAddModal={() => {
						setAddEditModal(true);
					}}
					searchPlaceholder="Search Electric Vehicles"
				/>
			}
		>
			<ColumnContainer
				width={'100%'}
				height="100%"
				extraSX={{ margin: 0, padding: 0 }}
			>
				<CustomDataGrid
					columns={vehicleColumns}
					rows={filteredData}
					isLoading={isLoading}
					isEmptyMessage="There are no electric vehicle assets to show"
					width="100%"
					height="65vh"
				/>
			</ColumnContainer>
			{addEditModal && (
				<AddEditVehicleModal
					closeModal={() => {
						setAddEditModal(false);
						setSelectedVehicle(null);
					}}
					modalOpen={addEditModal}
					vehicleAsset={selectedVehicle}
				/>
			)}

			{batteryPairModalOpen && (
				<PairBatteryModal
					toggleModal={() => {
						setBatteryPairModalOpen(false);
						setSelectedVehicle(null);
					}}
					modalOpen={batteryPairModalOpen}
					ev={selectedVehicle}
				/>
			)}

			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={() => {
					setDeleteModalOpen(false);
				}}
				onConfirmation={deleteEv}
				itemType="Electric vehicle"
				itemName={selectedVehicle?.registration_number}
				confirmType="delete"
			/>
		</CardContainer>
	);
}
