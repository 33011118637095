import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import {
	axisClasses,
	LineChart,
	lineElementClasses,
	markElementClasses,
	PieChart,
} from '@mui/x-charts';
import enzi_theme from '../../theme/theme';

export function BarChartItem({
	dataLabels = [],
	height = 400,
	width = undefined,
	dataSeries = [],
	chartLabel = '',
}) {
	// Transform dataLabels and dataSeries into the dataset format expected by BarChart
	const dataset = dataLabels.map((label, index) => {
		const dataPoint = { x: label };
		dataSeries.forEach((series, seriesIndex) => {
			dataPoint[`data${seriesIndex}`] = series.data[index];
		});
		return dataPoint;
	});

	// Transform dataSeries into the format expected by BarChart
	const transformedDataSeries = dataSeries.map((series, index) => ({
		dataKey: `data${index}`,
		label: series.label || `Series ${index + 1}`,
		valueFormatter: series.valueFormatter,
		color: series.color,
	}));

	return (
		<BarChart
			series={transformedDataSeries}
			dataset={dataset}
			margin={{
				left: 30,
				right: 0,
				top: 20,
				bottom: 20,
			}}
			height={height}
			width={width}
			sx={{
				[`.${axisClasses.left} .${axisClasses.label}`]: {
					transform: 'translate(-20px, 0)',
					color: enzi_theme.palette.primary.main,
				},
			}}
			xAxis={[{ dataKey: 'x', scaleType: 'band' }]}
			yAxis={[
				{
					label: chartLabel,
				},
			]}
			colors={transformedDataSeries.map((series) => series.color)}
		/>
	);
}

export function LineSingleChartItem({
	height = 400,
	width = undefined,
	chartLabel = 'Total Revenue (Ksh)',
	dataSeries = [
		{ data: [4000, 3000, 2000, 2780, 1890, 2390, 3490], label: 'pv' },
		{ data: [2400, 1398, 9800, 3908, 4800, 3800, 4300], label: 'uv' },
	],
	dataLabels = [
		'Page A',
		'Page B',
		'Page C',
		'Page D',
		'Page E',
		'Page F',
		'Page G',
	],
}) {
	return (
		<LineChart
			series={dataSeries}
			margin={{
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
			}}
			height={height}
			width={width}
			sx={{
				[`.${axisClasses.left} .${axisClasses.label}`]: {
					transform: 'translate(-20px, 0)',
					color: enzi_theme.palette.primary.main,
				},
				[`& .${markElementClasses.root}`]: {
					stroke: enzi_theme.palette.secondary.main,
					scale: '0.6',
					fill: enzi_theme.palette.secondary.main,
					strokeWidth: 2,
				},
			}}
			xAxis={[{ data: dataLabels, scaleType: 'point' }]}
			yAxis={[
				{
					label: chartLabel,
				},
			]}
			slotProps={{
				legend: {
					direction: 'row',
					position: { vertical: 'bottom', horizontal: 'middle' },
					backgroundColor: enzi_theme.palette.common.white,
					labelStyle: { fontSize: '0.75rem' },
				},
				itemContent: {
					sx: {
						backgroundColor: enzi_theme.palette.common.white,
						fontSize: '0.9rem',
						color: enzi_theme.palette.common.black,
					},
				},
				popper: {
					sx: {
						backgroundColor: enzi_theme.palette.common.white,
					},
				},
			}}
			colors={[
				enzi_theme.palette.secondary.main,
				enzi_theme.palette.primary.main,
				enzi_theme.palette.secondary.light,
				enzi_theme.palette.primary.light,
				enzi_theme.palette.background.default,
				enzi_theme.palette.background.paper,
			]}
		/>
	);
}

export function LineComplexChartItem({
	height = 400,
	width = undefined,
	dataset = [
		{ Q1: 21, Q2: 32, Q3: 53, Q4: 57, x: '2020' },
		{ Q1: 101, Q2: 142, Q3: 143, Q4: 161, x: '2021' },
		{ Q1: 221, Q2: 262, Q3: 297, Q4: 361, x: '2023' },
		{ Q1: 421, Q2: 492, Q3: 553, Q4: 599, x: '2024' },
	],
	chartLabel = 'Total Revenue (Ksh)',
	dataSeries = [
		{
			dataKey: 'Q1',
			label: 'Quarter 1',
			valueFormatter: (value) => `${value}k Ksh`,
		},
		{
			dataKey: 'Q2',
			label: 'Quarter 2',
			valueFormatter: (value) => `${value}k Ksh`,
		},
		{
			dataKey: 'Q3',
			label: 'Quarter 3',
			valueFormatter: (value) => `${value}k Ksh`,
		},
		{
			dataKey: 'Q4',
			label: 'Quarter 4',
			valueFormatter: (value) => `${value}k Ksh`,
		},
	],
}) {
	return (
		<LineChart
			series={dataSeries}
			dataset={dataset}
			margin={{
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
			}}
			height={height}
			width={width}
			sx={{
				[`.${axisClasses.left} .${axisClasses.label}`]: {
					transform: 'translate(-20px, 0)',
					color: enzi_theme.palette.primary.main,
				},
			}}
			xAxis={[{ dataKey: 'x', scaleType: 'point' }]}
			yAxis={[
				{
					label: chartLabel,
				},
			]}
			colors={[
				enzi_theme.palette.secondary.main,
				enzi_theme.palette.primary.main,
				enzi_theme.palette.secondary.light,
				enzi_theme.palette.primary.light,
				enzi_theme.palette.background.default,
				enzi_theme.palette.background.paper,
			]}
		/>
	);
}

export function AreaChartItem({
	dataset = [
		{ Q1: 21, Q2: 32, Q3: 53, Q4: 57, x: '2020' },
		{ Q1: 101, Q2: 142, Q3: 143, Q4: 161, x: '2021' },
		{ Q1: 221, Q2: 262, Q3: 297, Q4: 361, x: '2023' },
		{ Q1: 421, Q2: 492, Q3: 553, Q4: 599, x: '2024' },
	],
	height = 400,
	width = undefined,
	dataSeries = [
		{
			dataKey: 'Q1',
			label: 'Quarter 1',
			valueFormatter: (value) => `${value}k Ksh`,
			area: true,
			stack: 'total',
			// showMark: false,
		},
		{
			dataKey: 'Q2',
			label: 'Quarter 2',
			valueFormatter: (value) => `${value}k Ksh`,
			area: true,
			stack: 'total',
			// showMark: false,
		},
	],
}) {
	return (
		<LineChart
			series={dataSeries}
			dataset={dataset}
			margin={{
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
			}}
			height={height}
			width={width}
			sx={{
				[`& .${lineElementClasses.root}`]: {
					display: 'none',
				},
				[`.${axisClasses.left} .${axisClasses.label}`]: {
					transform: 'translate(-20px, 0)',
					color: enzi_theme.palette.primary.main,
				},
			}}
			xAxis={[{ dataKey: 'x', scaleType: 'point' }]}
			yAxis={[
				{
					label: 'Total Revenue (Ksh)',
				},
			]}
			colors={[
				enzi_theme.palette.secondary.main,
				enzi_theme.palette.primary.main,
				enzi_theme.palette.secondary.light,
				enzi_theme.palette.primary.light,
				enzi_theme.palette.background.default,
				enzi_theme.palette.background.paper,
			]}
		/>
	);
}

export function PieChartItem({
	height = 400,
	width = undefined,
	dataSeries = [
		{ id: 0, value: 10, label: 'series A' },
		{ id: 1, value: 15, label: 'series B' },
		{ id: 2, value: 20, label: 'series C' },
	],
}) {
	return (
		<PieChart
			series={[{ data: dataSeries }]}
			margin={{
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
			}}
			height={height}
			width={width}
			slotProps={{
				legend: {
					direction: 'column',
					position: { vertical: 'bottom', horizontal: 'left' },
					backgroundColor: enzi_theme.palette.common.white,
					labelStyle: { fontSize: '0.75rem' },
				},
				itemContent: {
					sx: {
						backgroundColor: enzi_theme.palette.common.white,
						fontSize: '0.9rem',
						color: enzi_theme.palette.common.black,
					},
				},
				popper: {
					sx: {
						backgroundColor: enzi_theme.palette.common.white,
					},
				},
			}}
			colors={[
				enzi_theme.palette.secondary.main,
				enzi_theme.palette.primary.main,
				enzi_theme.palette.secondary.light,
				enzi_theme.palette.primary.light,
				enzi_theme.palette.background.default,
				enzi_theme.palette.background.paper,
			]}
		/>
	);
}
