import React, { useEffect, useState } from 'react';
import {
	CardContainer,
	ColumnContainer,
	RowContainer,
} from '../global/containers';
import InfoDetails from '../global/infoText';
import { getLastSwapReport } from '../../services/batteryService';
import { formatDate } from '../../util/util';
import LoadingOverlay from '../overlays/loading';
import { EmptyOverlays } from '../global/overlays';

export default function LastSwapCard({ bat_sn }) {
	const [report, setReport] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isEmptyMessage, setIsEmptyMessage] = useState('Loading...');

	useEffect(() => {
		setIsLoading(true);
		getLastSwapReport(bat_sn).then((response) => {
			if (response.date_created) {
				const report = {
					issuing_station:
						response?.battery_swap_details?.issued_by_swapping_station_details
							?.name,
					receiving_station:
						response?.battery_swap_details?.received_by_swapping_station_details
							?.name,
					receiving_attendant: response?.battery_swap_details?.received_by_name,
					issuing_attendant: response?.battery_swap_details?.issued_by_name,
					swap_date: formatDate(response?.date_created),
					invoiced_amount: response?.amount,
					status: response?.status_name,
					issued_charge:
						response?.battery_swap_details?.issued_charge_percentage,
					received_charge:
						response?.battery_swap_details?.received_charge_percentage,
				};
				setReport(report);
			} else {
				setIsEmptyMessage('No report found');
				setReport({});
			}
		});
		setIsLoading(false);
	}, [bat_sn]);
	
	return (
		<CardContainer
			// title="Last Inspection Report"
			title="Last Battery Swap"
			width={{ xs: '100%', lg: '40%' }}
			height={{ xs: 'auto', lg: '100%' }}
			childrenContainerWidth="97%"
			extraSX={{
				borderRadius: '10px',
			}}
		>
			{isLoading ? (
				<LoadingOverlay height="100%" message={isEmptyMessage} />
			) : !isLoading && Object.keys(report).length === 0 ? (
				<EmptyOverlays message={isEmptyMessage} />
			) : (
				<RowContainer
					justifyContent="space-between"
					padding="0px"
					marginBottom="0px"
					alignItems="start"
				>
					<ColumnContainer padding="0px" width="33%" alignItems="left">
						<InfoDetails label={'Swap Date'} value={report?.swap_date} />
						<InfoDetails
							label={'Issuing Station'}
							value={report?.issuing_station}
						/>
						<InfoDetails
							label={'Receiving Station'}
							value={report?.receiving_station ?? '--'}
						/>
					</ColumnContainer>
					<ColumnContainer padding="0px" width="33%">
						<InfoDetails label={'Invoice Status'} value={report?.status} />
						<InfoDetails
							label={'Issued Charge %'}
							value={report?.issued_charge}
						/>
						<InfoDetails
							label={'Received Charge %'}
							value={report?.received_charge ?? '--'}
						/>
					</ColumnContainer>
					<ColumnContainer
						width="33%"
						padding="0px"
						alignItems={{ xs: 'flex-start', lg: 'end' }}
					>
						<InfoDetails
							label={'Invoiced Amount'}
							value={report?.invoiced_amount}
							alignItems={{ xs: 'flex-start', lg: 'end' }}
						/>
						<InfoDetails
							label={'Issued By'}
							value={report?.issuing_attendant ?? '--'}
							alignItems={{ xs: 'flex-start', lg: 'end' }}
						/>
						<InfoDetails
							label={'Received By '}
							value={report?.receiving_attendant ?? '--'}
							alignItems={{ xs: 'flex-start', lg: 'end' }}
						/>
					</ColumnContainer>
				</RowContainer>
			)}
		</CardContainer>
	);
}
