import React, { useCallback, useEffect, useState } from 'react';
import { RowContainer } from '../global/containers';
import StatItem from '../global/statItem';
import { riderStats } from '../../services/riderService';

export default function ManageRidersStatistics() {
	const [stats, setStats] = useState({});

	const getStats = useCallback(async function () {
		riderStats()
			.then((res) => {
				setStats(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		getStats();
	}, [getStats]);

	return (
		<RowContainer
			justifyContent="flex-start"
			width="100%"
			responsive={false}
			extraSX={{ gap: '10px', flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
		>
			<StatItem
				width="25%"
				title={'Total Active Riders'}
				value={stats?.active_riders}
			/>
			<StatItem
				width="25%"
				title={'Average Rider’s Vehicle Mileage'}
				value={stats?.average_mileage}
			/>

			<StatItem
				width="25%"
				title={'Average Battery Lease Income'}
				value={'-'}
			/>
			<StatItem width="25%" title={'Average Battery Lease Time'} value={'-'} />
		</RowContainer>
	);
}
