import React, { useCallback, useEffect, useState } from "react";
import ChargeListCard from "./chargeListCard";
import { chargingStationMetrics } from "../../services/metricsService";
import { handleErrorResponse } from "../../util/util";

export default function ChargeStatsCard({ handleRowClick }) {
  const [loading, setLoading] = useState(true);
  const [chargeStationList, setChargeStationList] = useState([]);
  const [timeRange, setTimeRange] = useState("day");

  const customChargeColumns = [
    { field: "name", headerName: "Station Name", flex: 1 },
    { field: "active_batteries", headerName: "Active Batteries", flex: 1 },
    { field: "idle_batteries", headerName: "Idle Batteries", flex: 1 },
    { field: "capacity", headerName: "Capacity", flex: 1 },
    {
      field: "batteries_charged",
      headerName: "Batteries Charged",
      flex: 1,
    },
    {
      field: "power_consumed",
      headerName: "Power Consumed (Kwh)",
      flex: 1,
    },
    { field: "utilization", headerName: "Utilization (%)", flex: 1 },
  ];

  const fetchChargeStationList = useCallback(async function (range) {
    setLoading(true);
    try {
      const res = await chargingStationMetrics(range);
      if (res && Array.isArray(res.stations)) {
        const formattedData = res.stations.map((station) => ({
          id: station.id,
          chargeStationName: station.name,
          dailySwapAverage: Number(
            (
              (station.g3_swaps + station.g5_swaps) /
              (range === "week" ? 7 : range === "day" ? 1 : 30)
            ).toFixed(2)
          ),
          monthsRevenue: parseFloat(station.revenue),
          battery_dispatched: station.battery_inventory_count,
          capacity: station.capacity,
          utilization: station.utilization_rate,
          powerConsumed: station.power_consumed,
          batteries_dispatched: station.batteries_dispatched,
          ...station,
        }));
        setChargeStationList(formattedData);
      } else {
        setChargeStationList([]);
      }
    } catch (error) {
      handleErrorResponse("Error fetching charging station metrics");
      setChargeStationList([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchChargeStationList(timeRange);
  }, [fetchChargeStationList, timeRange]);

  return (
    <ChargeListCard
      title="Charge Station Metrics"
      height={{ xs: "auto", lg: "100%" }}
      width={{ xs: "100%", lg: "50%" }}
      showTimeRange={true}
      showAddButton={false}
      showSearch={false}
      showDownload={true}
      customColumns={customChargeColumns}
      onRowClick={handleRowClick("charge")}
      chargeStationList={chargeStationList}
      isLoading={loading}
      timeRange={timeRange}
      setTimeRange={setTimeRange}
      fetchChargeStationList={fetchChargeStationList}
      setChargeStationList={setChargeStationList}
    />
  );
}
