import { CREATE_USER, DELETE_USER, LIST_USER, PASSWORD_RESET_REQUEST, UPDATE_USER } from "../constants/url.constants";
import { serviceAPI } from "../services/service";
import { handleErrorResponse, issueSuccessMessage } from "../util/util";

export const addUser = async (data) => {
	try {
		const response = await serviceAPI.post(CREATE_USER, data);
		issueSuccessMessage("User Added Successfully");
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error;
	}
};

export const listUsers = async () => {
	try {
		const response = await serviceAPI.get(LIST_USER);
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error;
	}
};

export const updateUser = async (id, data) => {
	try {
		const response = await serviceAPI.put(UPDATE_USER + id, data);
		issueSuccessMessage("User Added Successfully");
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error;
	}
};

export const deleteUser = async (id) => {
	try {
		const response = await serviceAPI.delete(DELETE_USER + id);
		issueSuccessMessage("User Added Successfully");
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
        throw error;
	}
};


export const requestPasswordReset = async (data) => {
	try {
		return await serviceAPI.post(PASSWORD_RESET_REQUEST, data);
	} catch (error) {
		handleErrorResponse(error);
		throw error;
	}
}

export const resetPassword = async (data) => {
	try {
		const response = await serviceAPI.post('/auth/reset-password/confirm', data);
		issueSuccessMessage('Password reset successful');
		return response.data;
	} catch (error) {
		handleErrorResponse(error);
		throw error;
	}
}