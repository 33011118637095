import React from "react";
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from "../global/containers";
import InfoDetails from "../global/infoText";

export default function DispatchOperationsModal({
	modalOpen,
	toggleModal,
	selectedDispatch,
}) {
	// Check if selectedDispatch is defined before rendering
	if (!selectedDispatch) {
		return null; // Or return a loading state if preferred
	}

	return (
		<ModalContainer
			title={`Dispatch Operation: #${selectedDispatch.id || "N/A"} - ${selectedDispatch.operation_type}`} // Use the ID from selectedDispatch
			modalOpen={modalOpen}
			height="auto"
			type="in-actionable"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={toggleModal}
		>
			<RowContainer
				justifyContent="space-between"
				alignItems="flex-start"
				width="100%"
				style={{ flexWrap: "nowrap" }}
			>
				<ColumnContainer
					padding="0px"
					width="48%"
					alignItems="flex-start"
				>
					<InfoDetails
						label={"Dispatch Date"}
						value={selectedDispatch.operation_date || "N/A"}
					/>
					<InfoDetails
						label={"Total Batteries"}
						value={selectedDispatch.batteries_total || "N/A"}
					/>

					<InfoDetails
						label={"Batteries Verified"}
						value={selectedDispatch.batteries_verified || "N/A"}
					/>
				</ColumnContainer>
				<ColumnContainer
					padding="0px"
					width="48%"
					alignItems="flex-start"
				>
					<InfoDetails
						label={"Source"}
						value={selectedDispatch.source_station || "N/A"}
					/>

					<InfoDetails
						label={"Destination"}
						value={selectedDispatch.destination_station || "N/A"}
					/>

					<InfoDetails
						label={"Dispatched By"}
						value={selectedDispatch.dispatched_by || "N/A"}
					/>
				</ColumnContainer>
			</RowContainer>
		</ModalContainer>
	);
}
