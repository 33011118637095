import React, { useCallback, useEffect, useState } from "react";
import { CardContainer } from "../global/containers";
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from "../global/customTable";
import DispatchOperationsModal from "../modals/dispatchOperationsModal";
import { requestedDispatchOperations } from "../../services/swapStation";

export default function RequestedDispatchOperationsCard({ station }) {
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [requestedDispatchList, setRequestedDispatchList] = useState([]);
	const [swappingStation, setSwappingStation] = useState(null);
	const [selectedDispatch, setSelectedDispatch] = useState(null);

	const requestedDispatchOperationsColumns = [
		{
			field: "charge_station",
			headerName: "Charge Station ",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "operation_status",
			headerName: "Operation Status",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "operation_type",
			headerName: "Operation Type",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "operation_date",
			headerName: "Operation Date",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "batteries_total",
			headerName: "Total Batteries",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "batteries_verified",
			headerName: "Batteries Verified",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "actions",
			headerName: "Actions",
			minWidth: 100,
			flex: 1,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					width="100px"
					menuOptions={[
						{
							label: "View",
							onClickHandler: () => toggleModal(params.row),
						},
					]}
				/>
			),
		},
	];

	const fetchRequestedDispatchOperations = useCallback(
		async function () {
			if (!station || !station.id) {
				console.error("Station information is missing.");
				return;
			}

			setIsLoading(true);
			try {
				const response = await requestedDispatchOperations(station.id);
				if (response && response.dispatch_operations) {
					// Ensure that each item in the list has a unique id
					const uniqueDispatchList = response.dispatch_operations.map(
						(operation, index) => ({
							...operation,
							id: operation.id || index,
						})
					);
					setRequestedDispatchList(uniqueDispatchList);
					setSwappingStation(response.swap_station);
				} else {
					setRequestedDispatchList([]);
				}
			} catch (error) {
				console.error("Error fetching dispatch operations:", error);
				setRequestedDispatchList([]);
			} finally {
				setIsLoading(false);
			}
		},
		[station]
	);

	useEffect(() => {
		fetchRequestedDispatchOperations();
	}, [fetchRequestedDispatchOperations]);

	const toggleModal = useCallback((dispatch) => {
		setSelectedDispatch(dispatch);
		setModalOpen((prev) => !prev);
	}, []);

	return (
		<CardContainer
			title="Dispatch Operations"
			width="100%"
			height="100%"
			extraSX={{ overflow: "visible" }}
			childrenContainerWidth="97%"
		>
			<CustomDataGrid
				columns={requestedDispatchOperationsColumns}
				rows={requestedDispatchList}
				width="100%"
				height="100%"
				isLoading={isLoading}
				isEmptyMessage="There are no dispatch operations to show."
			/>
			{modalOpen && (
				<DispatchOperationsModal
					toggleModal={toggleModal}
					modalOpen={modalOpen}
					selectedDispatch={selectedDispatch}
				/>
			)}
		</CardContainer>
	);
}
