import React, { useRef } from 'react'
import { ModalContainer } from '../global/containers'
import AddRiderForm from '../forms/addRiderForm'
import { addRider } from '../../services/riderService'

export default function AddRiderModal({modalOpen = false,toggleModal}) {

  const handleSubmitRef = useRef()

  const handleSubmit = () => {
    const data = handleSubmitRef.current.getData()
    if(!data){
      return
    }
    
    handleAddRider(data);
  }

  const handleAddRider = (data) => {
    addRider(data)
      .then((res => toggleModal()))
      .catch(err => {})
  }


  return (
    <ModalContainer
        title='Add New Rider'
        modalOpen={modalOpen}
        height="auto"  
        actionButtonText = "Add Rider"
        handleCloseModal={toggleModal}
        handleCancelClick={toggleModal}
        handleActionClick={handleSubmit}
      >
        <AddRiderForm ref={handleSubmitRef} />
      </ModalContainer>
  )
}
