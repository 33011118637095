import React, { useRef, useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import enzi_theme from "../../theme/theme";
import { CustomText } from "./text";
import { RowContainer } from "./containers";
import { FloatingActionButton } from "./button";
import { DataGrid } from "@mui/x-data-grid";
import { MoreVert } from "@mui/icons-material";

import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import LoadingOverlay from "../overlays/loading";
import { EmptyOverlays } from "./overlays";

export default function CustomTable({ columns = [], data = [] }) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: enzi_theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "4px 16px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: enzi_theme.palette.background.default,
    },
    "&:nth-of-type(even)": {
      backgroundColor: enzi_theme.palette.common.white,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <RowContainer responsive={false} width={"100%"}>
      <Table
        sx={{
          width: "100%",
        }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell>
                <CustomText fontWeight="900" color="secondary" text={column} />
              </StyledTableCell>
            ))}
            <StyledTableCell>
              <CustomText fontWeight="900" color="secondary" text="Action" />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, indx) => (
            <StyledTableRow key={indx}>
              {columns.map((columnName) => (
                <StyledTableCell component="th" scope="row">
                  <CustomText
                    color={enzi_theme.palette.common.black}
                    variant="body1"
                    text={row[columnName]}
                  />
                </StyledTableCell>
              ))}
              <StyledTableCell component="th" scope="row">
                <FloatingActionButton onclick={() => {}} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </RowContainer>
  );
}

export function CustomDataGridActionsDropdownMenu({
  width = "200px",
  height,
  menuOptions = [{ label: "example", onClickHandler: () => {} }],
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <PopupState variant="popover" popupId="datagrid-dropdown-menu">
      {(popupState) => (
        <>
          <IconButton
            sx={{
              backgroundColor: enzi_theme.palette.primary.main,
              color: enzi_theme.palette.secondary.main,
            }}
            id="datagrid-dropdown-trigger"
            aria-controls={open ? "datagrid-dropdown-menu" : undefined}
            aria-haspopup={"true"}
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickListItem}
            {...bindTrigger(popupState)}
          >
            <MoreVert sx={{ fontSize: "1.25rem" }} />
          </IconButton>

          <Menu
            id="datagrid-dropdown-menu"
            MenuListProps={{
              "aria-labelledby": "datagrid-dropdown-trigger",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            slotProps={{}}
            sx={{
              ".MuiMenu-paper": {
                backgroundColor: enzi_theme.palette.background.default,

                maxHeight: height ? height : "400px",
                width,
                boxShadow: enzi_theme.shadows[8],
              },
            }}
            {...bindMenu(popupState)}
          >
            {menuOptions.map((option) => (
              <MenuItem
                key={option.label}
                onClick={() => {
                  option.onClickHandler();
                  popupState.close();
                }}
                sx={{
                  fontSize: ".9rem",
                  fontWeight: 600,
                  color: enzi_theme.palette.secondary.main,
                  textTransform: "capitalize",
                }}
                id={option.label}
                disabled={option.label === "example"}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  );
}

export function CustomDataGrid({
  columns = [],
  rows = [],
  width = "95%",
  height = "60vh",
  isLoading = false,
  isEmptyMessage = "No data was found.",
  enableCheckboxSelection = false,
  onRowClick,
  isHighlighted = false,
  highlightRow: rowHighlightCallback = () => {},
}) {
  const dataGridRef = useRef(null);

  const getRowClassName = isHighlighted
    ? (params) => rowHighlightCallback(params)
    : () => {};

  return (
    <RowContainer
      width={{ xs: "100%", lg: width }}
      height={height}
      ref={dataGridRef}
      extraSX={{
        overflowY: "hidden",
        margin: 0,
        padding: 0,
      }}
    >
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection={enableCheckboxSelection} // Use the prop here
        disableRowSelectionOnClick={!enableCheckboxSelection} // Disable row selection when checkboxes are not enabled
        onRowClick={onRowClick}
        width={"100%"}
        height={"100%"}
        sx={{
          ".MuiDataGrid-columnHeader": {
            backgroundColor: enzi_theme.palette.common.white,
            color: enzi_theme.palette.secondary.main,
            svg: {
              fill: enzi_theme.palette.secondary.main,
            },
          },
          ".MuiDataGrid-panelHeader": {
            borderRadius: "10px 10px 0 0",
          },
          ".MuiDataGrid-footerContainer": {
            backgroundColor: enzi_theme.palette.background.default,
          },
          ".root": {
            backgroundColor: enzi_theme.palette.common.white,
            borderRadius: "10px",
            margin: 0,
          },
        }}
        getRowClassName={getRowClassName}
        slots={{
          loadingOverlay: () => (
            <LoadingOverlay height="100%" message={isEmptyMessage} />
          ),
          noResultsOverlay: () => (
            <EmptyOverlays height="100%" message={isEmptyMessage} />
          ),
          noRowsOverlay: () => (
            <EmptyOverlays height="100%" message={isEmptyMessage} />
          ),
        }}
      />
    </RowContainer>
  );
}
