import React, { useEffect, useRef, useState } from 'react';
import { CardContainer } from '../global/containers';
import { BarChartItem } from '../global/charts';

export default function StationCommisions() {
	const cardContainerRef = useRef();

	const [cardContainerWidth, setCardContainerWidth] = useState(undefined);

	useEffect(() => {
		if (cardContainerRef !== null && cardContainerRef.current !== null) {
			const containerWidth = cardContainerRef.current.getContainerWidth();
			if (!isNaN(containerWidth)) {
				setCardContainerWidth(containerWidth);
			}
		}
	}, [cardContainerRef]);
	return (
		<CardContainer
			ref={cardContainerRef}
			title="Station Commissions"
			width={'100%'}
			padding="0"
			height="100%"
			childrenContainerWidth="97%"
			extraSX={{ overflow: 'visible' }}
		>
			<BarChartItem
				// height={400}
				width={cardContainerWidth * 0.95}
				// dataLabels={["January", "February", "March", "April", "May"]}

				dataSeries={[
					{
						dataKey: 'Q1',
						label: 'Quarter 1',
						valueFormatter: (value) => `${value}k Ksh`,
					},
					{
						dataKey: 'Q2',
						label: 'Quarter 2',
						valueFormatter: (value) => `${value}k Ksh`,
					},
					{
						dataKey: 'Q3',
						label: 'Quarter 3',
						valueFormatter: (value) => `${value}k Ksh`,
					},
					{
						dataKey: 'Q4',
						label: 'Quarter 4',
						valueFormatter: (value) => `${value}k Ksh`,
					},
				]}
				dataset={[
					// {
					// data: [231, 302, 936, 317, 871],
					// color: enzi_theme.palette.secondary.main,
					// valueFormatter: (value) => `${value}K Ksh`,
					// },
					{ Q1: 21, Q2: 32, Q3: 53, Q4: 57, x: '2020' },
					{ Q1: 21, Q2: 32, Q3: 53, Q4: 57, x: '2021' },
					{ Q1: 21, Q2: 32, Q3: 53, Q4: 57, x: '2022' },
					{ Q1: 21, Q2: 32, Q3: 53, Q4: 57, x: '2023' },
				]}
				chartLabel="Revenue Performance"
			/>
		</CardContainer>
	);
}
