import React from "react";
import { ColumnContainer } from "../components/global/containers";
import TopBar from "../components/global/topBar";
import BatteryStaticsCard from "../components/cards/batteryStaticsCard";
import BatteryListCard from "../components/cards/batteryListCard";

export default function BatteryAssetsListPage() {
  return (
    <ColumnContainer>
      <TopBar/>
      <BatteryStaticsCard />
      <BatteryListCard />
    </ColumnContainer>
  );
}
