import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React from 'react'

function CustomDatePicker({ label, handleChange, value, maxDate=null, minDate=null }) {
  const isOptional = label.includes("(Optional)");

  return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				label={label}
				onChange={handleChange}
				value={dayjs(value)}
				maxDate={dayjs(maxDate)}
				minDate={dayjs(minDate)}
				slotProps={{
					textField: {
						error: !isOptional && !value, // Show error if required and no value
						required: !isOptional,
						InputLabelProps: {
							style: {
								color: isOptional ? "inherit" : undefined,
							},
						},
					},
				}}
			/>
		</LocalizationProvider>
  );
}

export default CustomDatePicker