import React, { useCallback, useEffect, useState } from 'react';
import { CardContainer, ColumnContainer } from '../global/containers';
import {
	CustomDataGrid,
	CustomDataGridActionsDropdownMenu,
} from '../global/customTable';
import AddEditBatteryModal from '../modals/addEditBatteryModal';
import { useNavigate } from 'react-router-dom';
import {
	deleteBattery,
	enableBatterySMS,
	killBatterySMS,
	listBatteries,
} from '../../services/batteryService';
import { handleErrorResponse } from '../../util/util';
import ConfirmationModal from '../modals/confirmationModal';
import ActionableComponent from '../global/actionableComponent';

export default function BatteryListCard() {
	const navigator = useNavigate();

	const [AddmodalOpen, setAddModalOpen] = useState(false);
	const [editmodalOpen, setEditmodalOpen] = useState(false);
	const [batteryToEdit, setBatteryToEdit] = useState();

	const [batteryList, setBatteryList] = useState([]);
	const [filteredBatteryList, setFilteredBatteryList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [disableModalOpen, setDisableModalOpen] = useState(false);
	const [batteryToDelete, setBatteryToDelete] = useState();
	const [batteryToDisable, setBatteryToDisable] = useState();
	const [disabled, setDisabled] = useState(false);

	const batteryListColumns = [
		{
			field: 'batterySerial',
			headerName: 'Battery Serial No.',
			width: 100,
			flex: 1,
		},
		{
			field: 'bms_sn',
			headerName: 'BMS Serial No.',
			width: 100,
			flex: 1,
		},
		{
			field: 'chargePercentage',
			headerName: 'Charge %',
			minWidth: 70,
			flex: 0.5,
		},
		{
			field: 'cycles',
			headerName: 'Cycles',
			minWidth: 100,
			flex: 0.5,
		},
		{
			field: 'deployed_ev_reg',
			headerName: 'Issued To',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'currentStatus',
			headerName: 'Current Status',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'tel',
			headerName: 'Tel.',
			width: 100,
			flex: 1,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => (
				<CustomDataGridActionsDropdownMenu
					menuOptions={[
						{
							label: 'View',
							onClickHandler: () =>
								navigator(`/batteries/${params.row.batterySerial}`),
						},
						{
							label: 'Edit',
							onClickHandler: () => editBatteryInfo(params.row),
						},
						{
							label: 'Delete',
							onClickHandler: () => handleDeleteClick(params.row),
						},
						{
							label: params.row.state === 'dis' ? 'Enable' : 'Disable',
							onClickHandler: () => handleBatteryDisable(params.row),
						},
					]}
				/>
			),
		},
	];

	const fetchBatteryList = useCallback(async function () {
		setIsLoading(true);
		listBatteries()
			.then((res) => {
				const formatedData = res.map((batteryItem) => {
					return {
						id: batteryItem?.id,
						batterySerial: batteryItem?.bat_sn,
						chargePercentage: batteryItem?.data?.pct ?? 0,
						cycles: batteryItem?.data?.cycles ?? 0,
						currentStatus: batteryItem?.state_name,
						...batteryItem,
					};
				});
				setBatteryList(formatedData);
				setFilteredBatteryList(formatedData);
			})
			.catch((err) => {
				setBatteryList([]);
			})
			.finally(() => setIsLoading(false));
	}, []);

	const handleBatteryDisable = (battery) => {
		setBatteryToDisable(battery);
		if (battery.state === 'dis') {
			setDisabled(true);
			setDisableModalOpen(true);
		} else {
			setDisabled(false);
			setDisableModalOpen(true);
		}
	};

	const disableBattery = () => {
		killBatterySMS(batteryToDisable?.id).finally(() =>
			setDisableModalOpen(false)
		);
	};

	const enableBattery = () => {
		enableBatterySMS(batteryToDisable?.id).finally(() => {
			setDisableModalOpen(false);
		});
	};

	useEffect(() => {
		if (editmodalOpen || AddmodalOpen || disableModalOpen) {
			return;
		}
		fetchBatteryList();
	}, [AddmodalOpen, editmodalOpen, disableModalOpen, fetchBatteryList]);

	const handleDelete = () => {
		deleteBattery(batteryToDelete.id)
			.then((res) => {
				const filteredList = batteryList.filter(
					(value) => value.id !== batteryToDelete?.id
				);
				setBatteryList(filteredList);
				setFilteredBatteryList(filteredList);
				setDeleteModalOpen(false);
			})
			.catch((err) => {
				console.log(err);
				handleErrorResponse(err);
			});
	};

	const handleDeleteClick = (battery) => {
		setBatteryToDelete(battery);
		setDeleteModalOpen(true);
	};

	const editBatteryInfo = (data) => {
		setBatteryToEdit(data);
		setEditmodalOpen(!editmodalOpen)
	};

	const filter = (ev) => {
		const param = ev.target.value.toLowerCase().trim();
		if (!param) {
			setFilteredBatteryList(batteryList);
			return;
		}
		const filters = (batteryItem) =>
			batteryItem.batterySerial.toLowerCase().includes(param.toLowerCase()) ||
			batteryItem.bms_sn
				.toString()
				.toLowerCase()
				.includes(param.toLowerCase()) ||
			batteryItem.currentStatus.toLowerCase().includes(param.toLowerCase()) ||
			batteryItem.cycles
				.toString()
				.toLowerCase()
				.includes(param.toLowerCase()) ||
			batteryItem.chargePercentage
				.toString()
				.toLowerCase()
				.includes(param.toLowerCase());
		const formattedData = batteryList.filter(filters);
		setFilteredBatteryList(formattedData);
	};

	const highlightRow = (battery) => {
		if (battery?.state_name?.toLowerCase() === 'disabled') {
			return 'disabledBattery';
		}

		return '';
	};

	const handleDownload = () => {
		const csvContent =
			'data:text/csv;charset=utf-8,' +
			['Battery Serial,Charge Percentage,Cycles,Current Status']
				.concat(
					batteryList.map(
						(battery) =>
							`${battery.batterySerial},${battery.chargePercentage},${battery.cycles},${battery.currentStatus}`
					)
				)
				.join('\n');

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'battery_inventory.csv');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<CardContainer
			title="Battery Assets"
			height="75vh"
			width="100%"
			childrenContainerWidth="98%"
			hasActionableComponent={true}
			actionableComponent={
				<ActionableComponent
					showAddButton={true}
					showSearch={true}
					showDownload={true}
					toggleAddModal={() => {
						setAddModalOpen(!AddmodalOpen);
					}}
					filter={filter}
					searchPlaceholder="Search Battery Assets"
					actionableText="Download Inventory"
					handleActionableClick={handleDownload}
				/>
			}
		>
			<ColumnContainer
				width={'100%'}
				height="100%"
				extraSX={{ margin: 0, padding: 0 }}
			>
				<CustomDataGrid
					isLoading={isLoading}
					columns={batteryListColumns}
					rows={filteredBatteryList}
					height="65vh"
					width="100%"
					isEmptyMessage="There are no battery assets to show"
					isHighlighted={true}
					highlightRow={(params) => highlightRow(params.row)}
				/>
			</ColumnContainer>
			{AddmodalOpen && (
				<AddEditBatteryModal
					toggleModal={() => {
						setAddModalOpen(!AddmodalOpen);
					}}
					modalOpen={AddmodalOpen}
				/>
			)}
			{editmodalOpen && (
				<AddEditBatteryModal
					modalOpen={editmodalOpen}
					toggleModal={() => {
						setEditmodalOpen(!editmodalOpen);
					}}
					battery={batteryToEdit}
				/>
			)}
			<ConfirmationModal
				modalOpen={deleteModalOpen}
				toggleModal={() => setDeleteModalOpen(false)}
				itemType={'Battery'}
				itemName={batteryToDelete?.batterySerial}
				onConfirmation={handleDelete}
				confirmType={'delete'}
			/>

			<ConfirmationModal
				modalOpen={disableModalOpen}
				toggleModal={() => setDisableModalOpen(false)}
				itemType={'Battery'}
				itemName={batteryToDisable?.batterySerial}
				onConfirmation={disabled ? enableBattery : disableBattery}
				confirmType={disabled ? 'enable' : 'disable'}
			/>
		</CardContainer>
	);
}
