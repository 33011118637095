import {
	PRINT_DOCUMENTS,
	UPLOAD_TAX_DOCUMENTS,
} from '../constants/url.constants';
import { serviceAPI, serviceAPIUpload } from '../services/service';
import { issueErrorMessage, issueSuccessMessage } from '../util/util';

export const printDocument = async (SearchParams) => {
	try {
		const response = await serviceAPI.get(PRINT_DOCUMENTS + SearchParams, {
			responseType: 'blob',
		});
		issueSuccessMessage('Document downloaded Successfully');
		return response.data;
	} catch (error) {
		issueErrorMessage('Document not downloaded');
		throw error;
	}
};

export const uploadPaymentRecords = async (formData) => {
	try {
		const response = await serviceAPIUpload.post(
			UPLOAD_TAX_DOCUMENTS,
			formData
		);
		return response.data;
	} catch (error) {
		console.log(error);
		issueErrorMessage('Document not upload');
		throw error;
	}
};

