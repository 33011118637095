import { RowContainer } from "./containers";
import { FormSelect } from "./form";
import { FloatingActionButton } from "./button";
import { Add } from "@mui/icons-material";
import { SearchInput } from "./form";
import { Button } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const TimeRangeSelector = ({ value, onChange }) => (
	<FormSelect
		value={value}
		onChange={onChange}
		options={["day", "week", "month"]}
		width="120px"
		placeholder="Select time range"
		light={true}
		height="100%"
		marginBottom="0px"
		
	/>
);

const AddButton = ({ onClick }) => (
	<FloatingActionButton
		icon={<Add color="secondary" />}
		onclick={onClick}
		width="auto"
	/>
);

const SearchInputWrapper = ({ placeholder, filter }) => (
	<SearchInput
		placeholder={placeholder}
		type="text"
		width={{ xs: "100%", md: "50%" }}
		backgroundColor="#FFFFFF"
		filter={filter}
	/>
);

const ActionButton = ({ text, onClick }) => (
	<Button
		onClick={onClick}
		variant="text"
		color="secondary"
		sx={{
			width: "auto",
			// height: "60px",
			fontWeight: 700,
			textTransform: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			gap: "10px",
			backgroundColor: "rgba(0, 0, 0, 0.08)",
		}}
	>
		{text}
		{text.toLowerCase().includes("see more") && <ChevronRightIcon />}
		{text.toLowerCase().includes("download") && <CloudDownloadIcon />}
	</Button>
);

export default function ActionableComponent({
	showTimeRange = false,
	showAddButton = false,
	showSearch,
	showDownload = false,
	handleTimeRangeChange = () => {},
	toggleAddModal = () => {},
	filter = () => {},
	timeRange = "day",
	searchPlaceholder = "Search",
	actionableText = "",
	handleActionableClick = () => {},
}) {
	return (
		<RowContainer
			width={{ xs: "90%", sm: "70%", lg: "40%" }}
			padding="0px"
			marginBottom="0px"
			justifyContent="flex-end"
			alignItems="center"
			extraSX={{
				gap: "16px",
				paddingRight: "16px",
				flexDirection: "row",
				flexWrap: "nowrap",
			}}
		>
			{showTimeRange && (
				<TimeRangeSelector
					value={timeRange}
					onChange={handleTimeRangeChange}
				/>
			)}
			{showAddButton && <AddButton onClick={toggleAddModal} />}
			{showSearch && (
				<SearchInputWrapper
					placeholder={searchPlaceholder}
					filter={filter}
				/>
			)}
			{showDownload && actionableText && (
				<ActionButton
					text={actionableText}
					onClick={handleActionableClick}
				/>
			)}
		</RowContainer>
	);
}
