import React from 'react';
import { ColumnContainer } from '../components/global/containers';
import TopBar from '../components/global/topBar';
import VehicleListCard from '../components/cards/vehicleListCard';
import VehicleAssetStatistics from '../components/cards/vehicleAssetStatistics';

export default function ElectricVehicleListPage() {
	return (
		<ColumnContainer>
			<TopBar />
			<VehicleAssetStatistics />
			<VehicleListCard />
		</ColumnContainer>
	);
}
