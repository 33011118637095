import React, { useEffect, useRef, useState } from "react";
import { CardContainer } from "../global/containers";
import { PieChartItem } from "../global/charts";
import { getBatteryStatus } from "../../services/batteryService";
export default function BatteryStatusCard() {
  const cardContainerRef = useRef();
  const [batteryStatus, setBatteryStatus] = useState(undefined);

  const [cardContainerWidth, setCardContainerWidth] = useState(undefined);

  useEffect(() => {
    if (cardContainerRef !== null && cardContainerRef.current !== null) {
      const containerWidth = cardContainerRef.current.getContainerWidth();
      if (!isNaN(containerWidth)) {
        setCardContainerWidth(containerWidth * 0.8);
      }
    }
  }, [cardContainerRef]);

  useEffect(() => {
    getBatteryStatus().then((res) => {
      setBatteryStatus(res);
    });
  }, []);

  return (
    <CardContainer
      ref={cardContainerRef}
      title="Battery Status"
      width={{ xs: "100%", lg: "40%" }}
      childrenContainerWidth={"95%"}
    >
      <PieChartItem
        height={300}
        width={cardContainerWidth}
        dataSeries={[
          {
            id: 0,
            value: batteryStatus?.Charging,
            label: "Charging",
            valueFormatter: (value) => `${value} batteries`,
          },
          {
            id: 1,
            value: batteryStatus?.Discharging,
            label: "Discharging",
            valueFormatter: (value) => `${value} batteries`,
          },
          {
            id: 2,
            value: batteryStatus?.Idle,
            label: "Idle",
            valueFormatter: (value) => `${value} batteries`,
          },
        ]}
      />
    </CardContainer>
  );
}
