import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ColumnContainer, RowContainer } from "../components/global/containers";
import TopBar from "../components/global/topBar";
import StationBasicInfoCard from "../components/cards/stationBasicInfoCard";
import EditStationModal from "../components/modals/editStationModal";
import SessionAttendantCard from "../components/cards/sessionAttendantCard";
import RequestedDispatchOperationsCard from "../components/cards/requestedDispatchOperationsCard";
import SessionSwapsCard from "../components/cards/sessionSwapsCard";
import { getSwapStationDetails } from "../services/swapStation";

export default function SwapStationInformation() {
	const { id } = useParams();
	const [swapStation, setSwapStation] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchSwapStationData = useCallback(async () => {
		setIsLoading(true);
		setError(null);
		if (!id) {
			setError("Invalid ID");
			setIsLoading(false);
			return;
		}
		try {
			const data = await getSwapStationDetails(id);
			if (!data || Object.keys(data).length === 0) {
				// Check for empty object
				throw new Error("No data found"); // Handle no data case
			}
			setSwapStation(data);
		} catch (error) {
			console.error("Error fetching swapping station data:", error);
			setError("Error fetching data. Please try again."); // Set error message
		} finally {
			setIsLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchSwapStationData();
	}, [fetchSwapStationData]);

	const handleStationUpdate = useCallback((updatedStation) => {
		setSwapStation(updatedStation);
	}, []);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error}</div>; // Display error message
	}

	if (!swapStation || Object.keys(swapStation).length === 0) {
		// Check for empty object
		return <div>Error: Swap station not found</div>; // Handle case where swapStation is null
	}

	return (
		<ColumnContainer
			height="auto"
			marginBottom="0px"
			extraSX={{
				overflowY: "auto",
				gap: "10px",
			}}
		>
			<TopBar/>
			<RowContainer
				justifyContent="flex-start"
				height={"340px"}
				extraSX={{
					gap: "10px",
					flexWrap: { xs: "wrap", lg: "nowrap" },
				}}
			>
				<StationBasicInfoCard
					station={swapStation}
					onStationUpdate={handleStationUpdate}
					EditModal={EditStationModal}
				/>{" "}
				{/* Pass fetched details */}
				<SessionAttendantCard station={swapStation} />{" "}
				{/* Replace this line */}
			</RowContainer>
			<RowContainer
				justifyContent="flex-start"
				height={"50vh"}
				extraSX={{
					gap: "10px",
					flexWrap: { xs: "wrap", lg: "nowrap" },
				}}
			>
				<RequestedDispatchOperationsCard station={swapStation} />{" "}
				{/* Pass fetched details */}
			</RowContainer>
			<RowContainer
				justifyContent="flex-start"
				height={"50vh"}
				extraSX={{
					gap: "10px",
					flexWrap: { xs: "wrap", lg: "nowrap" },
				}}
			>
				<SessionSwapsCard station={swapStation} />{" "}
			</RowContainer>
		</ColumnContainer>
	);
}
