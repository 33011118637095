export const BATTERY_MODEL_OPTIONS = [
	{
		value: 1,
		label: 'G3',
	},
	{
		value: 2,
		label: 'G5',
	},
];

export const BATTERY_TYPE_OPTIONS = [
	{
		value: 1,
		label: 'Lead-Acid',
	},
	{
		value: 2,
		label: 'Li-Ion',
	},
];

export const EV_TYPE_OPTIONS = [
	{
		value: 1,
		label: 'Bike',
	},
	{
		value: 2,
		label: 'Tuk',
	},
];
export const EV_TYPE = {
	Bike: 1,
	Tuk: 2,
};
