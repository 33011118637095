import React, { useCallback, useEffect, useState } from 'react';
import { RowContainer } from '../global/containers';
import StatItem from '../global/statItem';
import { getElectricVehiclesStats } from '../../services/electricVehicle';

export default function VehicleAssetStatistics() {
	const [stats, setStats] = useState();

	const fetchStats = useCallback(async function () {
		getElectricVehiclesStats({ timeRange: 'month' }).then((res) => {
			setStats(res);
		});
	}, []);

	useEffect(() => {
		fetchStats();
	}, [fetchStats]);

	return (
		<RowContainer
			justifyContent="space-between"
			width="100%"
			extraSX={{ gap: 1, flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
		>
			<StatItem
				title={'Total Active Electric Vehicles '}
				value={stats?.active_vehicles ?? '-'}
			/>
			<StatItem
				title={'Average Vehicle Mileage'}
				value={`${stats?.average_distance ?? '-'} KMs`}
			/>
			<StatItem
				title={'Average Vehicle Consumption'}
				value={`${stats?.average_consumption ?? '-'} %`}
			/>
			<StatItem
				title={"Total Month’s Vehicles' Revenue"}
				value={`${stats?.months_revenue ?? '-'} Ksh`}
			/>
		</RowContainer>
	);
}
