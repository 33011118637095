import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	listStations,
	listUnassignedBatteries,
	pairBattery,
} from '../../services/batteryService';
import { ColumnContainer, ModalContainer } from '../global/containers';
import {
	handleErrorResponse,
	handleInfoResponse,
	isEmptyString,
	isNullUndefined,
	sortJSONArray,
} from '../../util/util';
import { FormSelect } from '../global/form';
const PairBatteryModal = ({ modalOpen = false, ev = null, toggleModal }) => {
	const [serialError, setSerialError] = useState(null);
	const [batteryList, setBatteryList] = useState([]);
	const user = useSelector((state) => state.auth.user);
	const [battery, setBattery] = useState(null);

	useEffect(() => {
		listUnassignedBatteries().then((response) => {
			if (!isNullUndefined(response?.data)) {
				const batOptions = response.data
					.sort(sortJSONArray('bms_sn', false))
					.reduce((result, item) => {
						if (!isNullUndefined(item?.battery_location)) {
							return result.concat({
								label: `${item.bms_sn} - ${item?.data?.pct}% at ${item?.battery_location}`,
								value: item.bms_sn,
							});
						}
						return result;
					}, []);
				setBatteryList(batOptions);
			}
		});
	}, []);

	const handleSubmit = () => {
		if (isEmptyString(battery)) {
			setSerialError('You must select a battery to pair with the EV');
			return;
		} else {
			setSerialError(null);
			const sendData = {
				bms_sn: battery,
				ev_id: ev.id,
				user_id: user.id,
			};
			pairBattery(sendData)
				.then((response) => {
					handleInfoResponse(response?.data);
				})
				.catch((error) => handleErrorResponse(error))
				.finally(() => toggleModal());
		}
	};

	return (
		<ModalContainer
			title={`Pair Battery to #${ev?.registration_number}`}
			modalOpen={modalOpen}
			height="auto"
			actionButtonText={'Pair Battery'}
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={handleSubmit}
		>
			<ColumnContainer justifyContent="start" padding="0px">
				<FormSelect
					onChange={(e) => {
						setBattery(e.target.value);
					}}
					required={true}
					label="Available Batteries"
					placeholder={'Select a Battery'}
					options={batteryList}
					value={battery ?? null}
					error={serialError ? serialError : ''}
				/>
				{/* <FormSelect
					onChange={(e) => {
						setStation(e.target.value);
					}}
					required={true}
					label="Battery Location"
					placeholder={'Select the Battery location'}
					options={stationList}
					value={station ?? null}
					error={serialError ? serialError : ''}
				/> */}
			</ColumnContainer>
		</ModalContainer>
	);
};

export default PairBatteryModal;
