import React, { useCallback, useEffect, useState } from 'react';
import { ColumnContainer } from '../components/global/containers';
import Map from '../components/global/map';
import TopBar from '../components/global/topBar';
import AssetMapForm from '../components/forms/assetMapForm';
import AssetsKeys from '../components/cards/assetsKeys';
import { Box } from '@mui/material';
import {
	getAssetsCoordinates,
	getBatteryLocations,
	getLocationName,
	getVehicleLocations,
} from '../services/dashboardService';

export default function AssetMap() {
	const [markersList, setMarkersList] = useState([]);
	const [filteredMarkersList, setFilteredMarkersList] = useState([]);
	const [selectedMarker, setSelectedMarker] = useState(null);
	const [getLocation, setGetLocation] = useState(false);

	const updateVehicleLocations = useCallback(() => {
		getVehicleLocations().then((res) => {
			const newList = markersList.filter(
				(item) =>
					item.asset.toLowerCase() !== 'bike' &&
					item.asset.toLowerCase() !== 'tuk'
			);
			setMarkersList([...newList, ...res]);
		});
	}, [markersList, setMarkersList]);

	const updateBatteryLocations = useCallback(() => {
		getBatteryLocations().then((res) => {
			const newList = markersList.filter(item => item.asset.toLowerCase() === 'battery');
			setMarkersList([...newList, ...res]);
		})
	}, [markersList, setMarkersList]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			updateVehicleLocations();
		}, 120 * 1000);
		return () => clearInterval(intervalId);
	}, [updateVehicleLocations]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			updateBatteryLocations();
		}, 60 * 60 * 1000);
		return () => clearInterval(intervalId);
	}, [updateBatteryLocations]);

	const getMapData = useCallback(() => {
		Promise.all([getAssetsCoordinates(), getVehicleLocations(), getBatteryLocations()]).then(
			([assets, vehicles, batteries]) => {
				const list = [...assets, ...vehicles, ...batteries];
				setMarkersList(list);
				setFilteredMarkersList(list);
			}
		);
	}, []);

	useEffect(() => {
		getMapData();
	}, [getMapData]);

	useEffect(() => {
		if (selectedMarker && getLocation) {
			getLocationName(selectedMarker.lat, selectedMarker.lon)
				.then((res) => {
					setSelectedMarker({
						...selectedMarker,
						location: res,
					});
				})
				.finally(() => setGetLocation(false));
		}
	}, [getLocation, markersList, selectedMarker]);

	return (
		<ColumnContainer
			height="100%"
			marginBottom="0px"
			padding="0px"
			extraSX={{
				overflowY: 'auto',
				position: 'relative',
			}}
		>
			<TopBar marginBottom="0px">
				<AssetMapForm
					setFilteredMarkersList={setFilteredMarkersList}
					markersList={markersList}
				/>
			</TopBar>
			<Box
				zIndex={1}
				display={'flex'}
				alignItems={'end'}
				flexDirection={'column'}
				gap={1}
				position={'absolute'}
				bottom={'4%'}
				right={'5%'}
			>
				<AssetsKeys />
			</Box>
			{filteredMarkersList && (
				<Map
					key={JSON.stringify(filteredMarkersList)}
					markers={filteredMarkersList}
					setSelectedMarker={setSelectedMarker}
					setGetLocation={setGetLocation}
				/>
			)}
		</ColumnContainer>
	);
}
