//SWAP STATION ENDPOINTS
export const SWAP_STATION = `${process.env.REACT_APP_API_URL}/swapping_station/`;
export const SAVE_SWAP_STATION = `${SWAP_STATION}add_swap_station/`;
export const EDT_SWAP_STATION = `${SWAP_STATION}edt_swap_station/`;
export const DEL_SWAP_STATION = `${SWAP_STATION}del_swap_station/`;
export const LIST_SWAP_STATION = `${SWAP_STATION}list`;
export const SWAP_STATION_DETAILS = `${SWAP_STATION}users/`;
export const SWAP_STATION_ADD_USER = `${SWAP_STATION}add_user/`;
export const SWAP_STATION_EDT_USER = `${SWAP_STATION}edit_user/`;
export const SWAP_STATION_DEL_USER = `${SWAP_STATION}del_user/`;
export const SWAP_STATION_OPERATIONS = `${SWAP_STATION}operations/`;
//BATTERY ENDPOINTS
export const BATTERY = `${process.env.REACT_APP_API_URL}/battery/`;
export const ADD_BATTERY = `${BATTERY}add_battery/`;
export const EDT_BATTERY = `${BATTERY}edt_battery/`;
export const DEL_BATTERY = `${BATTERY}del_battery/`;
export const BATTERY_LIST = `${BATTERY}list`;
export const BATTERY_UNSIGNED_lIST = `${BATTERY}battery_unassigned_list`;
export const BATTERY_DETAIL = BATTERY + 'detail/';
export const BATTERY_DEPLOYMENTS = BATTERY + 'deployments/';
export const LAST_BATTERY_SWAP_REPORT = `${BATTERY}last_swap/`;
export const DISABLE_BATTERY = BATTERY + 'disable_battery/';
export const ENABLE_BATTERY = BATTERY + 'enable_battery/';
export const GLOBAL_DISPATCH_OPERATIONS =
	BATTERY + 'global_dispatch_operations';
export const BATTERY_STATUS = `${BATTERY}battery_status`;
//CHARGE STATION ENDPOINTS
export const CHARGE_STATION = `${process.env.REACT_APP_API_URL}/charging_station/`;
export const CHARGE_STATION_DETAILS = `${CHARGE_STATION}users/`;
export const CHARGE_STATION_ADD = `${CHARGE_STATION}add_charge_station/`;
export const CHARGE_STATION_DEL = `${CHARGE_STATION}del_charge_station/`;
export const CHARGE_STATION_EDT = `${CHARGE_STATION}edt_charge_station/`;
export const CHARGE_STATION_ADD_USER = `${CHARGE_STATION}add_user/`;
export const CHARGE_STATION_EDT_USER = `${CHARGE_STATION}edit_user/`;
export const CHARGE_STATION_List = `${CHARGE_STATION}list`;
export const CHARGE_STATION_OPERATIONS = `${CHARGE_STATION}operations/`;
export const CHARGE_STATION_DEL_USER = `${CHARGE_STATION}del_user/`;
//ELECTRIC VEHICLES
export const ELECTRIC_VEHICLE = `${process.env.REACT_APP_API_URL}/electric_vehicle/`;
export const ELECTRIC_VEHICLE_LIST = `${ELECTRIC_VEHICLE}list`;
export const AVAILABLE_ELECTRIC_VEHICLE_LIST = `${ELECTRIC_VEHICLE}available_list`;
export const ADD_ELECTRIC_VEHICLE = `${ELECTRIC_VEHICLE}add_electric_vehicle/`;
export const EDT_ELECTRIC_VEHICLE = `${ELECTRIC_VEHICLE}edt_electric_vehicle/`;
export const DEL_ELECTRIC_VEHICLE = `${ELECTRIC_VEHICLE}del_electric_vehicle/`;
export const ELECTRIC_VEHICLE_STATS = `${ELECTRIC_VEHICLE}electric_vehicle_stats/`;
export const ELECTRIC_VEHICLE_INFO = `${ELECTRIC_VEHICLE}detail/`;
export const ELECTRIC_VEHICLE_TRIPS = `${ELECTRIC_VEHICLE}trips/`;
export const ELECTRIC_VEHICLE_OVERVIEW = `${ELECTRIC_VEHICLE}electric_vehicle_overview/`;
export const TRIP_LOCATIONS = ELECTRIC_VEHICLE + "trip_location/";

//  RIDERS
export const RIDERS = `${process.env.REACT_APP_API_URL}/driver/`;
export const RIDER_LIST = `${RIDERS}list/`;
export const RIDER_DETAILS = `${RIDERS}driver_details/`;
export const RIDER_TRANSACTION = `${RIDERS}transactions/`;
export const ADD_RIDER = `${RIDERS}add_driver/`;
export const EDT_RIDER = `${RIDERS}edt_driver/`;
export const RIDER_STATS = `${RIDERS}driver_stats/`;
export const DEL_RIDER = `${RIDERS}del_driver/`;
export const TOP_RIDER = `${RIDERS}rider_performance/`;
export const RIDER_TRIPS_REPORT = `${RIDERS}trips-report/`;
//METRICS
export const DASHBOARD_METRICS = `${BATTERY}dashboardMetrics`;
export const BATTERY_ASSETS_METRICS = `${BATTERY}batteryAssetsMetrics`;
export const CHARGING_STATION_METRICS = `${CHARGE_STATION}metrics`;
export const SWAPPING_STATION_METRICS = `${SWAP_STATION}metrics`;

//USER MANAGEMENT
export const USERS = `${process.env.REACT_APP_API_URL}/users/`;
export const CREATE_USER = `${USERS}create_user/`;
export const LIST_USER = `${USERS}user_list/`;
export const UPDATE_USER = `${USERS}update_user/`;
export const DELETE_USER = `${USERS}delete_user/`;
export const LOGIN = `${process.env.REACT_APP_API_URL}/api/token/`;
export const REFRESH = `${process.env.REACT_APP_API_URL}/api/token_refresh/`;
export const LOGOUT = `${USERS}logout/`;

//ACCOUNTS
export const ACCOUNTS = `${process.env.REACT_APP_API_URL}/accounts/`;
export const PRINT_DOCUMENTS = `${ACCOUNTS}print_document`;

//DASHBOARD
export const CHARGING_AND_POWER_USAGE = BATTERY + 'charging_and_power_usage';
export const GLOBAL_FINANCIAL_REPORT = BATTERY + 'global_financial_report';

//MAP ENDPOINTS
export const STATION_ASSETS_COORDINATES = BATTERY + 'map/';
export const BATTERY_ASSETS_COORDINATES = BATTERY + 'battery_assets_locations/';
export const GET_LOCATION_NAME = BATTERY + 'get_location_name/';
export const ELECTRIC_VEHICLE_COORDINATES = ELECTRIC_VEHICLE + 'map/';


