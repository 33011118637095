import React from 'react';
import { ColumnContainer } from '../components/global/containers';
import ChargeStationStatistics from '../components/cards/chargeStationStatistics';
import TopBar from '../components/global/topBar';
import ChargeListCard from '../components/cards/chargeListCard';

export default function ChargeStationListPage() {
	return (
		<ColumnContainer>
			<TopBar />
			<ChargeStationStatistics />
			<ChargeListCard showSearch={true} />
		</ColumnContainer>
	);
}
