import React from 'react';
import {
	ColumnContainer,
	ModalContainer,
	RowContainer,
} from '../global/containers';
import InfoDetails from '../global/infoText';
import { formatDate } from '../../util/util';

export default function RiderTransactionModal({
	modalOpen,
	toggleModal,
	data,
}) {
	return (
		<ModalContainer
			title="Rider Transaction"
			modalOpen={modalOpen}
			height="auto"
			width={{ xs: '80%', md: '50%', xl: '30%' }}
			type="in-actionable"
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={toggleModal}
		>
			<RowContainer
				justifyContent="space-between"
				padding="0px"
				marginBottom="0px"
				width="95%"
			>
				<ColumnContainer padding="0px" width="50" alignItems="left">
					<InfoDetails
						label={'Invoice Date'}
						value={
							data?.invoice?.date_created
								? formatDate(data?.invoice?.date_created)
								: '-'
						}
					/>
					<InfoDetails
						label={'Paired Station'}
						value={data?.issued_by_swapping_station_details?.name}
					/>
					<InfoDetails
						label={'Start Charge Level'}
						value={`${data?.issued_charge_percentage ?? '-'} %`}
					/>
					<InfoDetails
						label={'Total Energy Consumed'}
						value={`${data?.consumed_energy ?? '-'} %`}
					/>
				</ColumnContainer>
				<ColumnContainer width="50" alignItems="left">
					<InfoDetails
						label={'Battery SN'}
						value={data?.battery_details?.bat_sn}
					/>
					<InfoDetails
						label={'Returned Station'}
						value={data?.issued_by_swapping_station_details?.name}
					/>
					<InfoDetails
						label={'End Charge Level'}
						value={`${data?.received_charge_percentage ?? '-'} %`}
					/>
					<InfoDetails
						label={'Total Energy Bill'}
						value={`${data?.bill_amount ?? '-'} Ksh`}
					/>
				</ColumnContainer>
			</RowContainer>
		</ModalContainer>
	);
}
