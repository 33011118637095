import { LOGIN } from '../constants/url.constants';
import { handleErrorResponse } from '../util/util';
import { serviceAPI } from '../services/service';
import { jwtDecode } from 'jwt-decode';
import { AUTHENTICATE_USER } from '../state/slices/auth';

export const login = (sendData) => async (dispatch) => {
	try {
		return await serviceAPI
			.post(LOGIN, sendData)
			.then((response) => {
				const data = response.data;
				const decoded = jwtDecode(data.access);
				dispatch(
					AUTHENTICATE_USER({
						isAuthenticated: true,
						user: {
							id: decoded.id,
							name: decoded.name,
							email: decoded.email,
							role: decoded.role,
							phone: decoded.phone,
							jwt: data.access,
							refresh: data.refresh,
							is_superuser: data.is_superuser,
							is_staff: data.is_staff,
						},
					})
				);
				return 'Login successful'
			})
			.catch((error) => {
				handleErrorResponse(error);
				throw error;
			});
	} catch (error) {
		handleErrorResponse(error);
		throw error;
	}
};

export const logout = () => {};

export const isTokenExpired = (token) => {
	try {
		const decoded = jwtDecode(token);
		if (decoded.exp < Date.now() / 1000) {
			// Checking if token is expired.
			return true;
		} else return false;
	} catch (err) {
		return false;
	}
};
