import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isAuthenticated: false,
	user: {
		id: null,
		name: '',
		email: '',
		role: '',
		phone: '',
		jwt: null,
		is_superuser: false,
		is_staff: false,
	},
};

export const authSlice = createSlice({
	name: 'authentication',
	initialState: initialState,
	reducers: {
		AUTHENTICATE_USER: (state, action) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			return { ...state, ...action.payload };
		},
		DE_AUTHENTICATE_USER: () => {
			return initialState;
		},
	},
});

// Action creators are generated for each case reducer function
export const { AUTHENTICATE_USER, DE_AUTHENTICATE_USER } = authSlice.actions;

export default authSlice.reducer;
