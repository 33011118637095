import React, { useState } from 'react';
import { ModalContainer } from '../global/containers';
import { addBattery, editBattery } from '../../services/batteryService';
import {
	formatTimestamp,
	isNullUndefined,
	issueWarnMessage,
} from '../../util/util';
import { ColumnContainer } from '../global/containers';
import { FormInput, FormSelect } from '../global/form';
import {
	BATTERY_MODEL_OPTIONS,
	BATTERY_TYPE_OPTIONS,
} from '../../constants/app.constants';

const AddEditBatteryModal = ({
	modalOpen = false,
	battery = null,
	toggleModal,
}) => {
	const [isError, setIsError] = useState(false);
	const [tel, setTel] = useState(battery?.tel ?? '');
	const [batModel, setBatModel] = useState(battery?.battery_model ?? '');
	const [batSn, setBatSn] = useState(battery?.bat_sn ?? '');
	const [batType, setBatType] = useState(battery?.battery_type ?? '');
	const [bmsSn, setBmsSn] = useState(battery?.bms_sn ?? '');
	const [serialError, setSerialError] = useState(null);

	const handleSubmit = () => {
		if (validate()) {
			onSubmit({
				bat_sn: batSn,
				bms_sn: bmsSn,
				battery_model: batModel,
				battery_type: batType,
				tel: tel,
			});
		} else {
			issueWarnMessage('Please fill all the required fields.');
		}
	};

	const validate = () => {
		if (!batSn || !batType || !batModel || !bmsSn || !tel) {
			setIsError(true);
			return false;
		}
		if (batSn) {
			if (batSn.includes(' ')) {
				setSerialError('Serial number cannot contain spaces.');
				setIsError(true);
				return false;
			} else if (batSn.length < 6) {
				setSerialError('Serial number must be at least 6 characters long.');
				setIsError(true);
				return false;
			} else if (batSn.length > 16) {
				setSerialError('Serial number cannot be longer than 16 characters.');
				setIsError(true);
				return false;
			} else if (!/^[a-zA-Z0-9]+$/.test(batSn)) {
				setSerialError('Serial number can only contain letters and numbers.');
				setIsError(true);
				return false;
			} else if (batSn[0] === '0') {
				setSerialError('Serial number cannot start with a zero.');
				setIsError(true);
				return false;
			}
		}
		setIsError(false);
		setSerialError(null);
		return true;
	};

	const onSubmit = (data) => {
		const timestamp = new Date();
		const sendData = {
			...data,
			ts: formatTimestamp(timestamp),
			data: {
				ip: '0.0.0.0',
				pct: 0,
				V: '0.00',
				C: '0.00',
				cap: '60.00',
				wf: '0.00',
				pf: '0.00',
				ff: '0.00',
				soc: '60.00',
				soh: '0.00',
				cycles: 0,
				cells: '0.00,0.00,0.00,0.00',
				Cell_T: '-61.40',
				Env_T: '0.00',
				fet_T1: '0.00',
				fet_T2: '0.00',
				model: '0',
				soft_ver: '1.0',
				hard_ver: '1.1',
				bms_sn: '0 ',
				ts: formatTimestamp(timestamp),
				wlt: false,
			},
		};
		if (isNullUndefined(battery)) {
			addBattery(sendData).then((res) => {
				toggleModal();
			});
		} else {
			editBattery(sendData, battery.id).then((res) => {
				toggleModal();
			});
		}
	};

	return (
		<ModalContainer
			title={
				!isNullUndefined(battery)
					? `Edit Battery Asset #${battery?.bat_sn}`
					: 'Add New Battery Asset'
			}
			modalOpen={modalOpen}
			height="auto"
			actionButtonText={
				!isNullUndefined(battery) === 'edit'
					? 'Update Battery Asset'
					: 'Save Battery Asset'
			}
			handleCloseModal={toggleModal}
			handleCancelClick={toggleModal}
			handleActionClick={handleSubmit}
		>
			{isError && (
				<p style={{ color: 'red' }}>Please meet all requirements. (*)</p>
			)}
			<ColumnContainer justifyContent="start" padding="0px">
				{isNullUndefined(battery) && (
					<FormInput
						onChange={(e) => {
							setBatSn(e.target.value);
						}}
						label="Battery Serial Number*"
						name={'batteryVin'}
						placeholder={'Enter Battery Serial Number'}
						type="text"
						required={true}
						value={batSn}
						error={
							serialError ||
							(isError && !batSn
								? 'This field is required.'
								: serialError
								? serialError
								: '')
						}
					/>
				)}

				<FormInput
					onChange={(e) => {
						setBmsSn(e.target.value);
					}}
					label="BMS Serial Number*"
					name={'bms_sn'}
					placeholder={'Enter BMS Serial Number'}
					type="text"
					required={true}
					value={bmsSn}
					error={isError && !bmsSn ? 'This field is required.' : ''}
				/>
				<FormSelect
					onChange={(e) => {
						setBatModel(e.target.value);
					}}
					required={true}
					label="Battery Model*"
					placeholder={'Select Battery Model'}
					options={BATTERY_MODEL_OPTIONS}
					value={batModel}
					error={isError && !batModel ? 'This field is required.' : ''}
				/>
				<FormSelect
					onChange={(e) => {
						setBatType(e.target.value);
					}}
					required={true}
					label="Battery Type*"
					placeholder={'Select Battery Type'}
					options={BATTERY_TYPE_OPTIONS}
					value={batType}
					error={isError && !batType ? 'This field is required.' : ''}
				/>
				<FormInput
					onChange={(e) => {
						setTel(e.target.value);
					}}
					label="Telephone Number*"
					name={'tel'}
					placeholder={'Enter Telephone number'}
					type="number"
					required={true}
					value={tel}
					error={isError && !tel ? 'This field is required.' : ''}
				/>
			</ColumnContainer>
		</ModalContainer>
	);
};

export default AddEditBatteryModal;
